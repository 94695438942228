/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
import axios, {CancelToken, CancelTokenSource} from 'axios';
import {Effect, Queue, Schedule, Duration} from 'effect';

import {EffectError} from './errors';
import {calculateBatchParameters, formatTime, logPerformanceMetrics} from './helpers';

export type ApiResponse<T> = {
  data: T[];
  headers?: {
    'content-range'?: string;
    [key: string]: any;
  };
};

declare global {
  interface BatchLoadTracker {
    operationId: number;
    completed: Set<number>;
    lastLoggedProgress: number;
    inProgress: boolean;
    startTime: number;
    fnName: string;
    cancelTokenSource: CancelTokenSource;
    projectId: string;
  }

  interface Window {
    _batchLoads: {
      [fnName: string]: BatchLoadTracker;
    };
  }
}

export type BatchTracking = {
  startTime: number;
  endTime?: number;
  itemCount: number;
};

export type BatchLoadConfig<T> = {
  batchFn: (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ) => Effect.Effect<ApiResponse<T>, EffectError, never>;
  fnName: string;
  projectId: string;
  batchSize?: number;
};

const effect = Effect.gen(function* () {
  const createBatchLoadEffect = <T>(config: BatchLoadConfig<T>): Effect.Effect<T[], EffectError, never> => {
    const {batchFn, fnName, projectId, batchSize} = config;
    const batchLoadId = Date.now();
    const logPrefix = `[${fnName} ${batchLoadId}]`;
    const operationStartTime = performance.now();
    const batchTracker = new Map<number, BatchTracking>();
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = axios.CancelToken.source();

    if (typeof window._batchLoads === 'undefined') {
      window._batchLoads = {};
    }

    const progressTracker: BatchLoadTracker = {
      operationId: batchLoadId,
      completed: new Set<number>(),
      lastLoggedProgress: 0,
      inProgress: true,
      startTime: operationStartTime,
      fnName,
      cancelTokenSource,
      projectId,
    };

    // Handle existing operations
    const existingOperation = window._batchLoads[fnName];
    if (existingOperation?.projectId !== projectId && existingOperation?.inProgress) {
      console.log(`${logPrefix} Cancelling operation for different project ${existingOperation.projectId}`);
      existingOperation.cancelTokenSource?.cancel('Project changed');
      existingOperation.inProgress = false;
    }

    window._batchLoads[fnName] = progressTracker;

    return Effect.gen(function* (_) {
      console.log(`${logPrefix} Starting load operation for project ${projectId}`);

      try {
        // Initial request to get total count
        const initialResponse = yield* _(batchFn(0, 1, cancelTokenSource.token));
        const contentRange = initialResponse.headers?.['content-range'];
        const total = contentRange
          ? parseInt(contentRange.match(/\d+-\d+\/(\d+)/)?.[1] ?? '0', 10)
          : initialResponse.data.length;

        if (total === 0) {
          console.log(`${logPrefix} No items to load`);
          return [];
        }

        const {
          batchSize: optimizedBatchSize,
          batchCount: numberOfBatches,
          concurrency: optimizedConcurrency,
        } = calculateBatchParameters(total, batchSize);

        console.log(`${logPrefix} Load parameters:`, {
          totalItems: total,
          batchSize: optimizedBatchSize,
          numberOfBatches,
          concurrency: optimizedConcurrency,
        });

        const resultQueue = yield* _(Queue.unbounded<ApiResponse<T>>());

        const processBatch = (index: number) =>
          Effect.gen(function* (_) {
            const batchStartTime = performance.now();

            batchTracker.set(index, {
              startTime: batchStartTime,
              itemCount: optimizedBatchSize,
            });

            console.log(`🚀 Starting batch ${index} at ${batchStartTime} for ${fnName}`);

            const result = yield* _(
              batchFn(index * optimizedBatchSize, optimizedBatchSize, cancelTokenSource.token).pipe(
                Effect.retry(Schedule.exponential(Duration.seconds(2)).pipe(Schedule.compose(Schedule.recurs(3)))),
              ),
            );

            const batchEndTime = performance.now();
            const batchDuration = batchEndTime - batchStartTime;

            const tracking = batchTracker.get(index);
            tracking.endTime = batchEndTime;
            tracking.itemCount = result.data.length;

            if (window._batchLoads[fnName]?.operationId === batchLoadId) {
              progressTracker.completed.add(index);
              console.log(
                `📥 Batch ${index} completed after ${formatTime(batchDuration)} [${(
                  tracking.itemCount /
                  (batchDuration / 1000)
                ).toFixed(0)} items/s] for ${fnName}`,
              );
            }

            yield* _(Queue.offer(resultQueue, result));
            return result;
          });

        // Process all batches concurrently
        yield* _(
          Effect.forEach(
            Array.from({length: numberOfBatches}, (_, i) => i),
            processBatch,
            {concurrency: optimizedConcurrency},
          ),
        );

        // Collect results
        const results = yield* _(
          Effect.forEach(Array.from({length: numberOfBatches}), () => Queue.take(resultQueue), {concurrency: 1}),
        );

        if (window._batchLoads[fnName]?.operationId === batchLoadId) {
          logPerformanceMetrics(
            batchTracker,
            operationStartTime,
            logPrefix,
            optimizedBatchSize,
            numberOfBatches,
            optimizedConcurrency,
          );
        }

        return results.flatMap((batch) => batch.data);
      } catch (error) {
        console.error(`${logPrefix} Operation failed:`, error);
        throw error;
      } finally {
        if (window._batchLoads[fnName]?.operationId === batchLoadId) {
          delete window._batchLoads[fnName];
        }
      }
    });
  };

  return {
    createBatchLoadEffect,
  };
});

export class BatchLoader extends Effect.Service<BatchLoader>()('BatchLoader', {
  effect,
}) {}

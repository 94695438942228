/* eslint-disable @typescript-eslint/naming-convention */
import {Effect, Cause, Layer} from 'effect';
import {camelizeKeys} from 'humps';

import {toShortIso} from 'shared/helpers/dates';
import {toGanttTaskModel} from 'shared/mapping/task';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskFilterQuery, TaskStatusQuery} from 'shared/models/task/filter';
import {TaskGanttModel, TaskModelRawDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {createAllTasksFetchBatch, createFetchBatch} from './api/createTasksEffect';
import {BatchLoadConfig, BatchLoader} from './BatchLoader';
import {EffectError} from './errors';
import {createProjectLoader} from './helpers';

const effect = Effect.gen(function* () {
  const batchLoader = yield* BatchLoader; // 👈 Create dependency

  const loadTasksOnGantt = (
    params: Partial<TaskFilterQuery & TaskStatusQuery>,
    setLoading?: (loading: boolean) => void,
  ): Effect.Effect<TaskGanttModel[], EffectError, never> => {
    const requestProjectId = params.projectId;

    if (!requestProjectId) {
      return Effect.fail(new Error('Project ID is required'));
    }

    // Create the loading effect
    const loadingEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(true);
      }
    });

    // Create the cleanup effect
    const cleanupEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(false);
      }
    });

    // Create the fetch batch
    const fetchBatch = createFetchBatch(params);

    // Create the batch effect
    const batchEffect = batchLoader.createBatchLoadEffect({
      batchFn: fetchBatch,
      fnName: 'loadTasks',
      projectId: requestProjectId,
    });

    // Transform the results
    const transformedEffect = batchEffect.pipe(
      Effect.map((results) => results.map((t) => toGanttTaskModel(camelizeKeys(t)))),
      Effect.catchAll((error) => (!Cause.isInterruptedException(error) ? Effect.fail(error) : Effect.succeed([]))),
    );

    // Combine all effects
    return loadingEffect.pipe(
      Effect.flatMap(() => createProjectLoader(requestProjectId, transformedEffect)),
      Effect.ensuring(cleanupEffect),
    );
  };

  const loadAllTasks = (
    projectId: string,
    loadDeleted = false,
  ): Effect.Effect<TaskModelRawDTO[], EffectError, never> => {
    const filterParams = {
      objectTypeList: [TaskObjectType.milestone, TaskObjectType.activity, TaskObjectType.task, TaskObjectType.summary],
      projectId,
      deleted: loadDeleted,
    };

    const fetchBatch = createAllTasksFetchBatch({
      filterParams,
    });

    const batchEffect = batchLoader.createBatchLoadEffect({
      batchFn: fetchBatch,
      fnName: 'loadAllTasks',
      projectId,
    });

    return createProjectLoader(projectId, batchEffect);
  };

  const fetchTasksByDate = (projectId: string, begin: Date, end: Date, setLoading?: (loading: boolean) => void) =>
    loadTasksOnGantt(
      {
        schedIntersect: [toShortIso(begin), toShortIso(end)],
        objectTypeList: ['task', 'activity'],
        projectId,
      },
      setLoading,
    );

  const fetchBlockedTasks = (projectId: string, setLoading?: (loading: boolean) => void) =>
    loadTasksOnGantt(
      {
        objectTypeList: ['task', 'activity'],
        status: TaskStatusType.blocked,
        projectId,
      },
      setLoading,
    );

  return {
    fetchBlockedTasks,
    fetchTasksByDate,
    loadAllTasks,
  } as const;
});

// eslint-disable-next-line new-cap
export class ConcurrentTaskLoader extends Effect.Service<ConcurrentTaskLoader>()('ConcurrentTaskLoader', {
  effect,
  dependencies: [BatchLoader.Default],
}) {
  static Test = <T>(mockData?: T[]) => {
    // For success cases with mock data
    if (Array.isArray(mockData)) {
      return this.DefaultWithoutDependencies.pipe(
        Layer.provide(
          Layer.succeed(BatchLoader, {
            createBatchLoadEffect: <U>(_config: BatchLoadConfig<U>): Effect.Effect<U[], Error, never> =>
              Effect.succeed(mockData as unknown as U[]),
            _tag: 'BatchLoader' as const,
          }),
        ),
      );
    }

    // Default case for error testing
    const defaultBatchLayer = Layer.succeed(BatchLoader, {
      createBatchLoadEffect: <T>(config: BatchLoadConfig<T>): Effect.Effect<T[], Error, never> => {
        return Effect.gen(function* () {
          const response = yield* config.batchFn(0, config.batchSize, null);
          return response.data;
        });
      },
      _tag: 'BatchLoader' as const,
    });

    return this.DefaultWithoutDependencies.pipe(Layer.provide(defaultBatchLayer));
  };
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable new-cap */
import {Effect, Layer} from 'effect';
import {InterruptedException} from 'effect/Cause';

import {LoadOptions} from 'modules/Tasks/components/Gantt/types';
import {TaskListMinimalModel} from 'shared/models/task/task';

import {createActivityIdsFetcherEffect} from './api/createActivityIdsEffect';
import {BatchLoadConfig, BatchLoader} from './BatchLoader';
import {EffectError} from './errors';
import {createProjectLoader} from './helpers';

const effect = Effect.gen(function* () {
  const batchLoader = yield* BatchLoader;

  const loadActivityIds = ({
    queryParams,
    projectId,
    dataRange,
    done,
    setLoading,
  }: LoadOptions): Effect.Effect<TaskListMinimalModel[] | InterruptedException, EffectError, never> => {
    // Create the loading effect
    const loadingEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(true);
      }
    });

    // Create the cleanup effect
    const cleanupEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(false);
      }
    });

    const fetchActivityIdsBatch = createActivityIdsFetcherEffect({
      queryParams,
      projectId,
      dataRange,
    });

    const fetchAndTransformData = batchLoader
      .createBatchLoadEffect({
        batchFn: fetchActivityIdsBatch,
        fnName: 'activityIdsLoader',
        projectId,
        batchSize: 5_000,
      })
      .pipe(
        Effect.map((results) => {
          const activityIds = results.map((task) => task.id);
          done(activityIds);
          return results;
        }),
      );

    return loadingEffect.pipe(
      Effect.flatMap(() => createProjectLoader(projectId, fetchAndTransformData)),
      Effect.ensuring(cleanupEffect),
    );
  };

  return {loadActivityIds} as const;
});

export class ConcurrentActivityIdsLoader extends Effect.Service<ConcurrentActivityIdsLoader>()(
  'ConcurrentActivityIdsLoader',
  {
    effect,
    dependencies: [BatchLoader.Default],
  },
) {
  static Test = <T>(mockData?: T[]) => {
    // For success cases with mock data
    if (Array.isArray(mockData)) {
      return this.DefaultWithoutDependencies.pipe(
        Layer.provide(
          Layer.succeed(BatchLoader, {
            createBatchLoadEffect: <U>(_config: BatchLoadConfig<U>): Effect.Effect<U[], Error, never> =>
              Effect.succeed(mockData as unknown as U[]),
            _tag: 'BatchLoader' as const,
          }),
        ),
      );
    }

    // Default case for error testing
    const defaultBatchLayer = Layer.succeed(BatchLoader, {
      createBatchLoadEffect: <T>(config: BatchLoadConfig<T>): Effect.Effect<T[], Error, never> => {
        return Effect.gen(function* () {
          const response = yield* config.batchFn(0, config.batchSize, null);
          return response.data;
        });
      },
      _tag: 'BatchLoader' as const,
    });

    return this.DefaultWithoutDependencies.pipe(Layer.provide(defaultBatchLayer));
  };
}

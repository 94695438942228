import {TasksViewMode} from 'shared/constants/common';

import {toTitleCase} from '../helpers/common';

type WorkerType = 'Project' | '';

export type MixpanelEvents = typeof mixpanelEvents;

const prfx = `C4_`;
const createEventName = (name) => `${prfx}${name}`;
const cen = createEventName;

const Events = {
  _ClickLookaheadButtonFromGantt: 'TasksToolbarCollabPopupSelectWorker',
  _ClickLookaheadButtonFromList: '_CollapseAllTasks',
  _CollapseAllTasks: '_ClickLookaheadButtonFromList',
  _ExpandAllTasks: 'SelectAllRowsCheckbox',
  ['AltA_SignupEmailSubmitButton']: 'AltA_SignupEmailSubmitButton',
  ['AltA_SignupEmailToggleButton']: 'AltA_SignupEmailToggleButton',
  ['AltA_SignupPage']: 'AltA_SignupPage',
  ['AltA_SignupPhoneReSendCodeButton']: 'AltA_SignupPhoneReSendCodeButton',
  ['AltA_SignupPhoneSendCodeButton']: 'AltA_SignupPhoneSendCodeButton',
  ['AltA_SignupPhoneToggleButton']: 'AltA_SignupPhoneToggleButton',
  ['AltA_SignupPhoneVerifyCodeSubmitButton']: 'AltA_SignupPhoneVerifyCodeSubmitButton',
  ['GetStarted_SignupEmailCancelButton']: 'GetStarted_SignupEmailCancelButton',
  ['GetStarted_SignupEmailCompanyCreated']: 'GetStarted_SignupEmailCompanyCreated',
  ['GetStarted_SignupEmailSubmitButton']: 'GetStarted_SignupEmailSubmitButton',
  ['GetStarted_SignupEmailUserClicksPolicyLink']: 'GetStarted_SignupEmailUserClicksPolicyLink',
  ['GetStarted_SignupEmailUserClicksTermsLink']: 'GetStarted_SignupEmailUserClicksTermsLink',
  ['GetStarted_SignupEmailWorkerCreated']: 'GetStarted_SignupEmailWorkerCreated',
  ['GetStarted_SignupPage']: 'GetStarted_SignupPage',
  ['GuestView_CheckedPunchlistItem']: 'GuestView_CheckedPunchlistItem',
  ['GuestView_SharedURL']: 'GuestView_SharedURL',
  ['GuestView_SharedURLDownloadAppButton']: 'GuestView_SharedURLDownloadAppButton',
  ['GuestView_UpdateCompletionAmount']: 'GuestView_UpdateCompletionAmount',
  ['GuestView_UpdateTaskStatus']: 'GuestView_UpdateTaskStatus',
  [`LandingLet'sTalkButton`]: `LandingLet'sTalkButton`,
  ActivityDetailsCommentTabAddPhoto: 'ActivityDetailsCommentTabAddPhoto',
  ActivityDetailsCommentTabCalendarPicker: 'ActivityDetailsCommentTabCalendarPicker',
  ActivityDetailsCommentTabFlagIssue: 'ActivityDetailsCommentTabFlagIssue',
  ActivityDetailsCommentTabSubmitCommentButton: 'ActivityDetailsCommentTabSubmitCommentButton',
  AddNewProjectButton: 'AddNewProjectButton',
  AfterSignupInsideC4HappyWorkingScreen: 'AfterSignupInsideC4HappyWorkingScreen',
  AfterSignupInsideC4HappyWorkingScreenClickFinishButton: 'AfterSignupInsideC4HappyWorkingScreenClickFinishButton',
  AfterSignupInsideC4HappyWorkingScreenDismiss: 'AfterSignupInsideC4HappyWorkingScreenDismiss',
  BackToMappingButton: 'FinishImportingCSVButton',
  BackToProfileButton: 'BackToProfileButton',
  BackToProjectsButton: 'BackToProjectsButton',
  BackToWorkersButton: 'BackToWorkersButton',
  BookDemo: 'BookDemo',
  ButtonClickCreateCompanyExistingC4User: 'ButtonClickCreateCompanyExistingC4User',
  ButtonClickCreateProfileNewCompanyExistingC4User: 'ButtonClickCreateProfileNewCompanyExistingC4User',
  ButtonClickCreateYourOwnCompanyExistingC4User: 'ButtonClickCreateYourOwnCompanyExistingC4User',
  ButtonClickSaveNewCompanyExistingC4User: 'ButtonClickSaveNewCompanyExistingC4User',
  SideNavChatButtonClick: 'SideNavChatButtonClick',
  ActivityDetailsMentionClickToChatRoom: 'ActivityDetailsMentionClickToChatRoom',
  IssueDetailsMentionClickToChatRoom: 'IssueDetailsMentionClickToChatRoom',
  CardEditProjectScreen: 'CardEditProjectScreen',
  ChatTabShowOnlyImagesCheckbox: 'ChatTabShowOnlyImagesCheckbox',
  CheckColumn: 'CheckColumn',
  ClickAddGeneralComments: 'ClickAddGeneralComments',
  ClickAssigneesIcon: 'ClickAssigneesIcon',
  ClickDailiesDatePicker: 'ClickDailiesDatePicker',
  ClickDailiesTab: 'ClickOnDailies',
  ClickOnGantt: 'ClickOnGantt',
  ClickOnGanttVisual: 'ClickOnTable',
  ClickOnLookahead: 'ClickOnLookahead',
  ClickOnTable: 'ClickOnGanttVisual',
  CloneProjectButton: 'CloneProjectButton',
  CloseLegendGantt: 'CloseLegendGantt',
  ColorMode: 'ColorMode',
  CompanyCloseButton: 'CompanyCloseButton',
  CompanySaveButton: 'CompanySaveButton',
  ConcurrentRequestTasksScreen: 'TasksToolbarFocusFilterSearchInput',
  CreateIssue3dotClick: 'CreateIssue3dotClick',
  CreateSubTaskButton: 'CreateSubTaskButton',
  CrewsbyCoreProHomepage: 'CrewsbyCoreProHomepage',
  DailiesActivityAddReportPopup: 'DailiesActivityAddReportPopup',
  DailiesActivityPopupAddPhoto: 'DailiesActivityPopupAddPhoto',
  DailiesActivityPopupCalendarPicker: 'DailiesActivityPopupCalendarPicker',
  DailiesActivityPopupEnterActualLabor: 'DailiesActivityPopupEnterActualLabor',
  DailiesActivityPopupEnterComment: 'DailiesActivityPopupEnterComment',
  DailiesActivityPopupEnterProgress: 'DailiesActivityPopupEnterProgress',
  DailiesActivityPopupEstLaborEdit: 'DailiesActivityPopupEstLaborEdit',
  DailiesActivityPopupFlagIssue: 'DailiesActivityPopupFlagIssue',
  DailiesActivityPopupSubmitButton: 'DailiesActivityPopupSubmitButton',
  DailiesActivityPopupTargetAmount: 'DailiesActivityPopupTargetAmount',
  DailiesActivityPopupUnitMeasurement: 'DailiesActivityPopupUnitMeasurement',
  DailiesActivityReportAddComment: 'DailiesActivityReportAddComment',
  DailiesActivityReportClockButton: 'DailiesActivityReportClockButton',
  DailiesActivityReportCreateIssue: 'DailiesActivityReportCreateIssue',
  DailiesGeneralCommentsAttachPhoto: 'DailiesGeneralCommentsAttachPhoto',
  DailiesGeneralCommentsSubmitButton: 'DailiesGeneralCommentsSubmitButton',
  DailRiskReportTasksButton: 'DailRiskReportTasksButton',
  DailyRiskReportButton: 'DailyRiskReportButton',
  DailyRiskReportNextDayButton: 'DailyRiskReportNextDayButton',
  DailyRiskReportPrintButton: 'DailyRiskReportPrintButton',
  DailyRiskReportTodayButton: 'DailyRiskReportTodayButton',
  DailyRiskReportTomorrowButton: 'DailyRiskReportTomorrowButton',
  DeleteButton: 'DeleteButton',
  DeleteProjectButton: 'DeleteProjectButton',
  DeselectActivityRowCheckbox: 'ImportLogInProCoreButton',
  DeselectAllRowsCheckbox: 'SelectActivityRowCheckbox',
  DisableBaseline: 'DisableBaseline',
  DownloadUpdatedCSVButton: 'GoToMyTaskButton',
  DragArrowDependency: 'DragArrowDependency',
  DragBarDueTaskDate: 'DragBarDueTaskDate',
  DragBarStartTaskDate: 'DragBarStartTaskDate',
  EditProfileButton: 'EditProfileButton',
  EnableBaseline: 'EnableBaseline',
  ExitNewProjectButton: 'ExitNewProjectButton',
  ExitNewTaskButton: 'ExitNewTaskButton',
  ExitNewWorkerButton: 'ExitNewWorkerButton',
  ExportDateFormatButton: 'LookaheadClickExportFileButton',
  FilterButton: 'FilterButton',
  FilterByApplyButtonClicked: 'FilterByApplyButtonClicked',
  FilterByCostImpact: 'FilterByCostImpact',
  FilterByCostTrackingNumber: 'FilterByCostTrackingNumber',
  FilterByCulpableOrg: 'FilterByCulpableOrg',
  FilterByImpact: 'FilterByImpact',
  FilterByIssueTrackingNumber: 'FilterByIssueTrackingNumber',
  FilterByIssueType: 'FilterByIssueType',
  FilterByLiableCompany: 'FilterByLiableCompany',
  FilterByLocation: 'FilterByLocation',
  FilterByMilestonesOnly: 'FilterByMilestonesOnly',
  FilterByResolvingCompany: 'FilterByResolvingCompany',
  FilterByResponsible: 'FilterByResponsible',
  FilterByStatus: 'FilterByStatus',
  FilterBySubcontractor: 'FilterBySubcontractor',
  FilterByType: 'FilterByType',
  FilterByVisibilityActivityWithIssues: 'FilterByVisibilityActivityWithIssues',
  FilterByVisibilityPastDueOnly: 'FilterByVisibilityPastDueOnly',
  FilterByVisibilityUnassignedActivities: 'FilterByVisibilityUnassignedActivities',
  FinishImportingCSVButton: 'BackToMappingButton',
  ForgotPasswordBackToLoginButtonClick: 'ForgotPasswordBackToLoginButtonClick',
  ForgotPasswordEmailSentBacktoLoginButtonClick: 'ForgotPasswordEmailSentBacktoLoginButtonClick',
  ForgotPasswordEmainNotFoundPage: 'ForgotPasswordEmainNotFoundPage',
  ForgotPasswordResetEnterPasswordsPage: 'ForgotPasswordResetEnterPasswordsPage',
  ForgotPasswordResetPasswordButtonClick: 'ForgotPasswordResetPasswordButtonClick',
  ForgotPasswordResetPasswordConfirmationAppStoreButton: 'ForgotPasswordResetPasswordConfirmationAppStoreButton',
  ForgotPasswordResetPasswordConfirmationGooglePlayButton: 'ForgotPasswordResetPasswordConfirmationGooglePlayButton',
  ForgotPasswordResetPasswordConfirmationLoginButtonClick: 'ForgotPasswordResetPasswordConfirmationLoginButtonClick',
  ForgotPasswordSendResetEmailButtonClick: 'ForgotPasswordSendResetEmailButtonClick',
  GalleryShowMoreButton: 'GalleryShowMoreButton',
  GoToMyTaskButton: 'DownloadUpdatedCSVButton',
  GoToTaskListButton: 'GoToTaskListButton',
  GridActivityAvgLaborButton: 'GridActivityAvgLaborButton',
  GridActivityCommentButton: 'GridActivityCommentButton',
  GridActivityDetailsCommentTabAddCommentButton: 'GridActivityDetailsCommentTabAddCommentButton',
  GridActivityLaborPopupAddPhoto: 'GridActivityLaborPopupAddPhoto',
  GridActivityLaborPopupCalendarPicker: 'GridActivityLaborPopupCalendarPicker',
  GridActivityLaborPopupEnterActualLabor: 'GridActivityLaborPopupEnterActualLabor',
  GridActivityLaborPopupEnterComment: 'GridActivityLaborPopupEnterComment',
  GridActivityLaborPopupEnterProgress: 'GridActivityLaborPopupEnterProgress',
  GridActivityLaborPopupEstLaborEdit: '"GridActivityLaborPopupEstLaborEdit',
  GridActivityLaborPopupFlagIssue: 'GridActivityLaborPopupFlagIssue',
  GridActivityLaborPopupSubmitButton: 'GridActivityLaborPopupSubmitButton',
  GridActivityLaborPopupTargetAmount: 'GridActivityLaborPopupTargetAmount',
  GridActivityLaborPopupUnitMeasurement: 'GridActivityLaborPopupUnitMeasurement',
  GridActivityProgressButton: 'GridActivityProgressButton',
  GridActivityProgressPopupAddPhoto: 'GridActivityProgressPopupAddPhoto',
  GridActivityProgressPopupCalendarPicker: 'GridActivityProgressPopupCalendarPicker',
  GridActivityProgressPopupEnterActualLabor: 'GridActivityProgressPopupEnterActualLabor',
  GridActivityProgressPopupEnterComment: 'GridActivityProgressPopupEnterComment',
  GridActivityProgressPopupEnterProgress: 'GridActivityProgressPopupEnterProgress',
  GridActivityProgressPopupEstLaborEdit: '"GridActivityProgressPopupEstLaborEdit',
  GridActivityProgressPopupFlagIssue: 'GridActivityProgressPopupFlagIssue',
  GridActivityProgressPopupSubmitButton: 'GridActivityProgressPopupSubmitButton',
  GridActivityProgressPopupTargetAmount: 'GridActivityProgressPopupTargetAmount',
  GridActivityProgressPopupUnitMeasurement: 'GridActivityProgressPopupUnitMeasurement',
  HaveQuestionsButton: 'HaveQuestionsButton',
  ImportCompleteExitButton: 'ImportNeedHelpButton',
  ImportCompleteScreen: 'ImportCSVStartExportButton',
  ImportCSVButton: 'UploadCSVButton',
  ImportCSVExitButton: 'ImportCSVNextButton',
  ImportCSVNextButton: 'ImportCSVExitButton',
  ImportCSVStartExportButton: 'ImportCompleteScreen',
  ImportDateMapping: 'ImportProjectMapping',
  ImportLogInProCoreButton: 'DeselectActivityRowCheckbox',
  ImportLogOutProCoreButton: 'ImportSelectFile',
  ImportNeedHelpButton: 'ImportCompleteExitButton',
  ImportProjectMapping: 'ImportDateMapping',
  ImportSelectFile: 'ImportLogOutProCoreButton',
  ImportShowAllRows: 'ImportShowMoreErrors',
  ImportShowMoreErrors: 'ImportShowAllRows',
  InlineEdit: 'InlineEdit',
  InviteSignupClickAppStoreButton: 'InviteSignupClickAppStoreButton',
  InviteSignupClickFinishButton: 'InviteSignupClickFinishButton',
  InviteSignupClickGoogleStoreButton: 'InviteSignupClickGoogleStoreButton',
  InviteSignupClickNextButton: 'InviteSignupClickNextButton',
  InviteSignupClickPolicyLink: 'InviteSignupClickPolicyLink',
  InviteSignupClickTermsLink: 'InviteSignupClickTermsLink',
  InviteSigupClickSendVerificationCodeButton: 'InviteSigupClickSendVerificationCodeButton',
  iPadEditLock: 'iPadEditLock',
  iPadEditUnlock: 'iPadEditUnlock',
  IssueDetailsDeleteButton: 'IssueDetailsDeleteButton',
  IssueDetailsGoBack: 'IssueDetailsGoBack',
  IssueDetailsLinkActivitiesButton: 'IssueDetailsLinkActivitiesButton',
  IssueDetailsSaveIssueInfo: 'IssueDetailsSaveIssueInfo',
  IssueDetailsUnlinkActivitiesButton: 'IssueDetailsUnlinkActivitiesButton',
  IssueIconClick: 'IssueIconClick',
  IssuesClickTabButton: 'ClickIssuesTab',
  IssuesViewClickCollaborateButton: 'IssuesViewClickCollaborateButton',
  IssuesViewDateFilter: 'IssuesViewDateFilter',
  IssuesViewPrintButton: 'IssuesViewPrintButton',
  LandingGetStartedButton: 'LandingGetStartedButton',
  LandingHeaderAppStoreButton: 'LandingHeaderAppStoreButton',
  LandingHeaderGooglePlayButton: 'LandingHeaderGooglePlayButton',
  LandingLanguageSwitchButton: 'LandingLanguageSwitchButton',
  LandingLoginButton: 'LandingLoginButton',
  LandOnInviteSignupbyEmailScreen: 'LandOnInviteSignupbyEmailScreen',
  LandOnInviteSignupbyPhoneScreen: 'LandOnInviteSignupbyPhoneScreen',
  LandOnInviteSignupWereAllSetScreen: 'LandOnInviteSignupWereAllSetScreen',
  LandsOn: 'LandsOn',
  LandsOnDailies: 'LandsOnDailies',
  LandsOnGantt: 'LandsOnGantt',
  LandsOnGanttVisual: 'LandsOnGanttVisual',
  LandsOnIssues: 'LandsOnIssues',
  LandsOnLookahead: 'LandsOnLookahead',
  LandsOnProjectOrgsScreen: 'LandsOnProjectOrgsScreen',
  LandsOnTable: 'LandsOnTable',
  LoginEmailEnterButton: 'LoginEmailEnterButton',
  LoginEmailToggleButton: 'LoginEmailToggleButton',
  LoginForgotPasswordButtonClick: 'LoginForgotPasswordButtonClick',
  LoginPage: 'LoginPage',
  LoginPageClickSignupHereButton: 'LoginPageClickSignupHereButton',
  LoginPhoneEnterButton: 'LoginPhoneEnterButton',
  LoginPhoneResendCodeButton: 'LoginPhoneResendCodeButton',
  LoginPhoneToggleButton: 'LoginPhoneToggleButton',
  LoginSendCodeButton: 'LoginSendCodeButton',
  LookaheadCanvasClickSetDate: 'LookaheadCanvasClickSetDate',
  LookaheadCanvasClickUnsetDate: 'LookaheadCanvasClickUnsetDate',
  LookaheadClickExportFileButton: 'ExportDateFormatButton',
  LookaheadCreateIssue3dotClick: 'LookaheadCreateIssue3dotClick',
  MakeAdminButton: 'MakeAdminButton',
  MakeForemanButton: 'MakeForemanButton',
  MapArrowButton: 'MapArrowButton',
  MapBrowseExistingFilesDropdown: 'MapBrowseExistingFilesDropdown',
  MapBrowseFiles: 'MapBrowseFiles',
  MapBrowseProfileFiles: 'MapBrowseProfileFiles',
  MapCalloutButton: 'MapCalloutButton',
  MapStampButton: 'MapStampButton',
  MapCalloutVisibilityButton: 'MapCalloutVisibilityButton',
  MapDropNewFile: 'MapDropNewFile',
  MapHandButton: 'MapHandButton',
  MapLineButton: 'MapLineButton',
  MapPolygonButton: 'MapPolygonButton',
  MapPolylineButton: 'MapPolylineButton',
  MapPrintButton: 'MapPrintButton',
  MapRectangleButton: 'MapRectangleButton',
  MapResizeDropdown: 'MapResizeDropdown',
  MapSelectExistingFilesDropdown: 'MapSelectExistingFilesDropdown',
  MapSelectNewFile: 'MapSelectNewFile',
  MapTextBoxButton: 'MapTextBoxButton',
  MapTypewriterButton: 'MapTypewriterButton',
  MapZoomOutButton: 'MapZoomOutButton',
  NewProjectButton: 'NewProjectButton',
  NewProjectCard: 'NewProjectCard',
  NewSnapshotButton: 'NewSnapshotButton',
  NewSnapshotPreview: 'NewSnapshotPreview',
  NewSnapshotSubmit: 'NewSnapshotSubmit',
  NewTaskAssigneeButton: 'NewTaskAssigneeButton',
  NewTaskBackToTasksButton: 'NewTaskBackToTasksButton',
  NewTaskCreateButton: 'NewTaskCreateButton',
  NewTaskEmptyRowClick: 'StartExportButton',
  NewTasksButton: 'NewTasksButton',
  NotifyWithEmailButton: 'NotifyWithEmailButton',
  NotifyWithSMSButton: 'NotifyWithSMSButton',
  OpenFilterDropdown: 'OpenFilterDropdown',
  OpenLegendGantt: 'OpenLegendGantt',
  OpenSidePanel: 'OpenSidePanel',
  ProcoreFileImportFileImported: 'ProcoreFileImportFileImported',
  ProcoreFileImportFileSelected: 'ProcoreFileImportFileSelected',
  ProcoreFileImportPopupClosed: 'ProcoreFileImportPopupClosed',
  ProcoreFileImportPopupLoggedOut: 'ProcoreFileImportPopupLoggedOut',
  ProcoreFileImportPopupOpened: 'ProcoreFileImportPopupOpened',
  ProcoreFileImportProjectSelected: 'ProcoreFileImportProjectSelected',
  ProfileClosedForm: 'ProfileClosedForm',
  ProfileOpenedForm: 'ProfileOpenedForm',
  ProfileSaveChanges: 'ProfileSaveChanges',
  Project: 'Project',
  ProjectCardTasksButton: 'ProjectCardTasksButton',
  ProjectNameLeftNavEditProjectScreen: 'ProjectNameLeftNavEditProjectScreen',
  ProjectOrgEditGoToWorkersButton: 'ProjectOrgEditGoToWorkersButton',
  ProjectOrgs: 'ProjectOrgs',
  ProjectOrgsDelete: 'ProjectOrgsDelete',
  ProjectOrgsEditOrgProfile: 'ProjectOrgsEditOrgProfile',
  ProjectOrgSelectColor: 'ProjectOrgSelectColor',
  ProjectOrgSelectResponsible: 'ProjectOrgSelectResponsible',
  ProjectOrgsToolbarCreateCompanyButton: 'ProjectOrgsToolbarCreateCompanyButton',
  ProjectOrgsToolbarSearchInputFocus: 'ProjectOrgsToolbarSearchInputFocus',
  ProjectPreviewBackToMappingButton: 'ProjectPreviewBackToMappingButton',
  ProjectPreviewDownloadUpdatedCSVButton: 'ProjectPreviewDownloadUpdatedCSVButton',
  ProjectPreviewExportButton: 'ProjectPreviewExportButton',
  ProjectPreviewExportDateFormatButton: 'ProjectPreviewExportDateFormatButton',
  ProjectPreviewFinishImportingCSVButton: 'ProjectPreviewFinishImportingCSVButton',
  ProjectPreviewGoToMyTaskButton: 'ProjectPreviewGoToMyTaskButton',
  ProjectPreviewImportButton: 'ProjectPreviewImportButton',
  ProjectPreviewImportCompleteExitButton: 'ProjectPreviewImportCompleteExitButton',
  ProjectPreviewImportCompleteScreen: 'ProjectPreviewImportCompleteScreen',
  ProjectPreviewImportCSVExitButton: 'ProjectPreviewImportCSVExitButton',
  ProjectPreviewImportCSVNextButton: 'ProjectPreviewImportCSVNextButton',
  ProjectPreviewImportCSVStartExportButton: 'ProjectPreviewImportCSVStartExportButton',
  ProjectPreviewImportDateMapping: 'ProjectPreviewImportDateMapping',
  ProjectPreviewImportMapping: 'ProjectPreviewImportMapping',
  ProjectPreviewImportNeedHelpButton: 'ProjectPreviewImportNeedHelpButton',
  ProjectPreviewStartExportButton: 'ProjectPreviewStartExportButton',
  ProjectPreviewUploadCSVButton: 'ProjectPreviewUploadCSVButton',
  ProjectsLeftNavHeader: 'ProjectsLeftNavHeader',
  ProjectsScreen: 'ProjectsScreen',
  ProjectsScreenSearchButton: 'ProjectsScreenSearchButton',
  ProjectViewTasksActiveTaskList: 'ProjectViewTasksActiveTaskList',
  QuestionMarkButton: 'QuestionMarkButton',
  RemoveWorkerButton: 'RemoveWorkerButton',
  ReportsGoToProjectTasksButton: 'ReportsGoToProjectTasksButton',
  ReportsGoToTaskButton: 'ReportsGoToTaskButton',
  ReportsProjectSelectionButton: 'ReportsProjectSelectionButton',
  ReportsScreen: 'ReportsScreen',
  ReportsTimeFrameButton: 'ReportsTimeFrameButton',
  SavedSnapshotsCopyLink: 'SavedSnapshotsCopyLink',
  SavedSnapshotsDelete: 'SavedSnapshotsDelete',
  SavedSnapshotsPopout: 'SavedSnapshotsPopout',
  SaveProjectButton: 'SaveProjectButton',
  SearchWorkerButton: 'SearchWorkerButton',
  SelectActivityRowCheckbox: 'DeselectAllRowsCheckbox',
  SelectAllRowsCheckbox: '_ExpandAllTasks',
  SelectCountryButton: 'SelectCountryButton',
  Selected: 'Selected',
  SelectTimezoneButton: 'SelectTimezoneButton',
  ShareTaskCopyLinkButtonClick: 'ShareTaskCopyLinkButtonClick',
  ShowPastDueTasksButton: 'ShowPastDueTasksButton',
  ShowTasksWithIssuesButton: 'ShowTasksWithIssuesButton',
  ShowWorkersButton: 'ShowWorkersButton',
  SidebarCreateIssueButton: 'SidebarCreateIssueButton',
  SidebarIssue3dotDeleteClick: 'SidebarIssue3dotDeleteClick',
  SidebarIssueCheckboxChecked: 'SidebarIssueCheckboxChecked',
  SidebarIssueCheckboxUnchecked: 'SidebarIssueCheckboxUnchecked',
  SignupPage: 'SignupPage',
  SignupStep1BrowseImageButton: 'SignupStep1BrowseImageButton',
  SignupStep1EmailSubmitButton: 'SignupStep1EmailSubmitButton',
  SignupStep1EmailToggleButton: 'SignupStep1EmailToggleButton',
  SignupStep1NextButton: 'SignupStep1NextButton',
  SignupStep1PhoneReSendCodeButton: 'SignupStep1PhoneReSendCodeButton',
  SignupStep1PhoneSendCodeButton: 'SignupStep1PhoneSendCodeButton',
  SignupStep1PhoneToggleButton: 'SignupStep1PhoneToggleButton',
  SignupStep1PhoneVerifyCodeSubmitButton: 'SignupStep1PhoneVerifyCodeSubmitButton',
  SignupStep1SelectedPhoto: 'SignupStep1SelectedPhoto',
  SignupStep2NextButton: 'SignupStep2NextButton',
  SignupStep2Page: 'SignupStep2Page',
  SignupStep3AddCompanySubmitButton: 'SignupStep3AddCompanySubmitButton',
  SignupStep3AddMyCompanyButton: 'SignupStep3AddMyCompanyButton',
  SignupStep3FinishButton: 'SignupStep3FinishButton',
  SignupStep3Page: 'SignupStep3Page',
  StartExportButton: 'NewTaskEmptyRowClick',
  TableCreateIssue3dotClick: 'TableCreateIssue3dotClick',
  TaskContextMenuAddAction: 'TaskContextMenuAddAction',
  TaskContextMenuAddActivity: 'TaskContextMenuAddActivity',
  TaskContextMenuAddAssignees: 'TaskContextMenuAddAssignees',
  TaskContextMenuAddIssue: 'TaskContextMenuAddIssue',
  TaskContextMenuClose: 'TaskContextMenuClose',
  TaskContextMenuDeleteButton: 'TaskContextMenuDeleteButton',
  TaskContextMenuOpen: 'TaskContextMenuOpen',
  TaskContextMenuOpenActivityDetails: 'TaskContextMenuOpenActivityDetails',
  TaskContextMenuShareButton: 'TaskContextMenuShareButton',
  TaskDetailsAssigneesTab: 'TaskDetailsAssigneesTab',
  TaskDetailsAssigneesTabClickAddGroupButton: 'TaskDetailsAssigneesTabClickAddGroupButton',
  TaskDetailsAssigneesTabLand: 'TaskDetailsAssigneesTabLand',
  TaskDetailsAssigneesTabSelectAllButton: 'TaskDetailsAssigneesTabSelectAllButton',
  TaskDetailsAssigneesTabSelectOrg: 'TaskDetailsAssigneesTabSelectOrg',
  TaskDetailsAssigneesTabSelectResponsible: 'TaskDetailsAssigneesTabSelectResponsible',
  TaskDetailsAssigneesTabUnselectAllButton: 'TaskDetailsAssigneesTabUnselectAllButton',
  TaskDetailsClickInviteMemberButton: 'TaskDetailsClickInviteMemberButton',
  TaskDetailsCommentsTabLand: 'TaskDetailsCommentsTabLand',
  TaskDetailsCompletionMeasurementButton: 'TaskDetailsCompletionMeasurementButton',
  TaskDetailsEditBackToTasks: 'TaskDetailsEditBackToTasks',
  TaskDetailsInviteNewMember: 'TaskDetailsInviteNewMember',
  TaskDetailsLocationButton: 'TaskDetailsLocationButton',
  TaskDetailsProjectButton: 'TaskDetailsProjectButton',
  TaskDetailsShareTaskButtonClick: 'TaskDetailsShareTaskButtonClick',
  TaskDetailsStatusButton: 'TaskDetailsStatusButton',
  TaskDetailsSubcontractorButton: 'TaskDetailsSubcontractorButton',
  TaskDetailsTabLand: 'TaskDetailsTabLand',
  TaskDetailsTypeButton: 'TaskDetailsTypeButton',
  TaskList: 'TaskList',
  TaskPanelClickActionsCheckbox: 'TaskPanelClickActionsCheckbox',
  TaskPanelClickActionsFinishDate: 'TaskPanelClickActionsFinishDate',
  TaskPanelClickActionsResponsible: 'TaskPanelClickActionsResponsible',
  TaskPanelClickAddActionsButton: 'TaskPanelClickAddActionsButton',
  TaskPanelClickAddIssuesButton: 'TaskPanelClickAddIssuesButton',
  TaskPanelClickArchiveButton: 'TaskPanelClickArchiveButton',
  TaskPanelClickAssigneesTab: 'TaskPanelClickAssigneesTab',
  TaskPanelClickChatTab: 'TaskPanelClickChatTab',
  TaskPanelClickDeleteButton: 'TaskPanelClickDeleteButton',
  TaskPanelClickShareButton: 'TaskPanelClickShareButton',
  TaskSaveInfoButton: 'TaskSaveInfoButton',
  TasksCollabLandingInvitedWorker: 'TasksCollabLandingInvitedWorker',
  TasksCollabLandingNotSignedInWorker: 'TasksCollabLandingNotSignedInWorker',
  TasksCollabLandingOpenSignedInWorker: 'TasksCollabLandingOpenSignedInWorker',
  TaskSidePanelClickBack: 'TaskSidePanelClickBack',
  TaskSidePanelClickEdit: 'TaskSidePanelClickEdit',
  TasksToolbarAssignResponsible: 'TasksToolbarAssignWatcher',
  TasksToolbarAssignSubcontractor: 'TasksToolbarPrevDayButtonClicked',
  TasksToolbarAssignWatcher: 'TasksToolbarAssignResponsible',
  TasksToolbarClickButtonCreateActivity: 'TasksToolbarClickUndoButton',
  TasksToolbarClickColorDropdown: 'TasksToolbarColorDropdownSetColor',
  TasksToolbarClickPrintButton: 'TasksToolbarClickTaskShareButton',
  TasksToolbarClickRedoButton: 'TasksToolbarSelectDate',
  TasksToolbarClickTaskShareButton: 'TasksToolbarClickPrintButton',
  TasksToolbarClickUndoButton: 'TasksToolbarClickButtonCreateActivity',
  TasksToolbarCollabPopupAddNewWorker: 'TasksToolbarCollabPopupSendRequest',
  TasksToolbarCollabPopupClosed: 'TasksToolbarCollabPopupOpened',
  TasksToolbarCollabPopupOpened: 'TasksToolbarCollabPopupClosed',
  TasksToolbarCollabPopupSelectWorker: '_ClickLookaheadButtonFromGantt',
  TasksToolbarCollabPopupSendRequest: 'TasksToolbarCollabPopupAddNewWorker',
  TasksToolbarColorDropdownResetColor: 'TasksToolbarDeleteButtonClick',
  TasksToolbarColorDropdownSetColor: 'TasksToolbarClickColorDropdown',
  TasksToolbarDeleteButtonClick: 'TasksToolbarColorDropdownResetColor',
  TasksToolbarFocusFilterSearchInput: 'ConcurrentRequestTasksScreen',
  TasksToolbarNextDayButtonClicked: 'TasksToolbarPrevWeekButtonClicked',
  TasksToolbarNextWeekButtonClicked: 'TasksToolbarZoomOutButtonClicked',
  TasksToolbarPrevDayButtonClicked: 'TasksToolbarAssignSubcontractor',
  TasksToolbarPrevWeekButtonClicked: 'TasksToolbarNextDayButtonClicked',
  TasksToolbarSelect: 'TasksToolbarSelect',
  TasksToolbarSelectDate: 'TasksToolbarClickRedoButton',
  TasksToolbarSelectTaskLocation: 'TasksToolbarSelectTaskType',
  TasksToolbarSelectTaskType: 'TasksToolbarSelectTaskLocation',
  TasksToolbarSelectWeeks: 'TasksToolbarZoomInButtonClicked',
  TasksToolbarZoomInButtonClicked: 'TasksToolbarSelectWeeks',
  TasksToolbarZoomOutButtonClicked: 'TasksToolbarNextWeekButtonClicked',
  UnbounceLeadMagnetFormSuccess: 'UnbounceLeadMagnetFormSuccess',
  UncheckColumn: 'UncheckColumn',
  UploadCSVButton: 'ImportCSVButton',
  VisualizationCreateIssue3dotClick: 'VisualizationCreateIssue3dotClick',
  Worker: 'Worker',
  WorkerAddButton: 'WorkerAddButton',
  WorkerProfileScreen: 'WorkerProfileScreen',
  WorkerSaveInfoButton: 'WorkerSaveInfoButton',
  WorkersScreen: 'WorkersScreen',
  WorkersToolbarFocusFilterSearchInput: 'WorkersToolbarFocusFilterSearchInput',
  WorkerSubmitInviteButton: 'WorkerSubmitInviteButton',
  WorkerToolbarFilter: 'WorkerToolbarFilter',
} as const;

export const mixpanelEvents = {
  // modules
  home: {
    screen: cen(Events.CrewsbyCoreProHomepage),
    download: {
      appStore: cen(Events.LandingHeaderAppStoreButton),
      googlePlay: cen(Events.LandingHeaderGooglePlayButton),
    },
    getStarted: cen(Events.LandingGetStartedButton),
    login: cen(Events.LandingLoginButton),
    languageSwitch: cen(Events.LandingLanguageSwitchButton),
    letsTalk: cen(Events[`LandingLet'sTalkButton`]),
  },
  share: {
    userOpenedPage: cen(Events.GuestView_SharedURL),
    updateTaskStatus: cen(Events.GuestView_UpdateTaskStatus),
    updatePunchList: cen(Events.GuestView_CheckedPunchlistItem),
    updatesCompletionProgress: cen(Events.GuestView_UpdateCompletionAmount),
    downloadApp: cen(Events.GuestView_SharedURLDownloadAppButton),
  },
  risk: {
    tasksButtonClicked: cen(Events.DailRiskReportTasksButton),
    reportButtonClicked: cen(Events.DailyRiskReportButton),
    printButtonClicked: cen(Events.DailyRiskReportPrintButton),
    todayButtonClicked: cen(Events.DailyRiskReportTodayButton),
    tomorrowButtonClicked: cen(Events.DailyRiskReportTomorrowButton),
    nextDayButtonClicked: cen(Events.DailyRiskReportNextDayButton),
  },
  task: {
    formFields: {
      status: cen(Events.TaskDetailsStatusButton),
      completionUnit: cen(Events.TaskDetailsCompletionMeasurementButton),
      projectId: cen(Events.TaskDetailsProjectButton),
      responsibleOrgId: cen(Events.TaskDetailsSubcontractorButton),
      type: cen(Events.TaskDetailsTypeButton),
      location: cen(Events.TaskDetailsLocationButton),
    },
    actions: {
      create: `${prfx}{status}${Events.NewTaskCreateButton}`,
      exit: `${prfx}{status}${Events.ExitNewTaskButton}`,
      backToTasks: `${prfx}{status}${Events.NewTaskBackToTasksButton}`,
      editBackToTasks: cen(Events.TaskDetailsEditBackToTasks),
      assigneeButtonFromGrid: cen(`{status}${Events.NewTaskAssigneeButton}`),
      assigneeTabLand: cen(Events.TaskDetailsAssigneesTabLand),
      commentsTabLand: cen(Events.TaskDetailsCommentsTabLand),
      taskDetailsTabLand: cen(Events.TaskDetailsTabLand),
      delete: cen(Events.DeleteButton),
      saveInfo: cen(Events.TaskSaveInfoButton),
      searchWorker: cen(Events.SearchWorkerButton),
      createSubTask: cen(Events.CreateSubTaskButton),
      share: cen(Events.TaskDetailsShareTaskButtonClick),
      copyTaskShareLink: cen(Events.ShareTaskCopyLinkButtonClick),
      inviteNewMember: cen(Events.TaskDetailsInviteNewMember),
      selectAssigneesOrg: cen(Events.TaskDetailsAssigneesTabSelectOrg),
      selectResponsible: cen(Events.TaskDetailsAssigneesTabSelectResponsible),
      // action = Select/Unselect
      toggleSelectTaskWatcher: (action) => cen(`${Events.TaskDetailsAssigneesTab}${action}${Events.Worker}`),
    },
    buttons: {
      showMorePhoto: cen(Events.GalleryShowMoreButton),
      showOnlyImages: cen(`${Events.ChatTabShowOnlyImagesCheckbox}{status}`), // status = Checked/Unchecked
      selectAllAssignees: cen(Events.TaskDetailsAssigneesTabSelectAllButton),
      unselectAllAssignees: cen(Events.TaskDetailsAssigneesTabUnselectAllButton),
      inviteMemberButton: cen(Events.TaskDetailsClickInviteMemberButton),
      selectAssigneesGroup: cen(Events.TaskDetailsAssigneesTabClickAddGroupButton),
    },
  },
  tasks: {
    screen: {
      [TasksViewMode.dailies]: cen(Events.LandsOnDailies),
      [TasksViewMode.gantt]: cen(Events.LandsOnGantt),
      [TasksViewMode.ganttVisual]: cen(Events.LandsOnGanttVisual),
      [TasksViewMode.issues]: cen(Events.LandsOnIssues),
      [TasksViewMode.lookahead]: cen(Events.LandsOnLookahead),
      [TasksViewMode.table]: cen(Events.LandsOnTable),
    },
    switchViewMode: {
      [TasksViewMode.dailies]: cen(Events.ClickDailiesTab),
      [TasksViewMode.gantt]: cen(Events.ClickOnGantt),
      [TasksViewMode.ganttVisual]: cen(Events.ClickOnGanttVisual),
      [TasksViewMode.issues]: cen(Events.IssuesClickTabButton),
      [TasksViewMode.lookahead]: cen(Events.ClickOnLookahead),
      [TasksViewMode.table]: cen(Events.ClickOnTable),
    },
    lockedOperation: cen(Events.ConcurrentRequestTasksScreen),
    toolbar: {
      ipadLock: (locked: boolean) => cen(locked ? Events.iPadEditLock : Events.iPadEditUnlock),
      searchInput: cen(Events.TasksToolbarFocusFilterSearchInput),
      addNewTaskBtn: cen(Events.TasksToolbarClickButtonCreateActivity),
      undo: cen(Events.TasksToolbarClickUndoButton),
      redo: cen(Events.TasksToolbarClickRedoButton),
      dateDropdown: {
        selectDate: cen(Events.TasksToolbarSelectDate),
        selectWeeks: cen(Events.TasksToolbarSelectWeeks),
        zoomIn: cen(Events.TasksToolbarZoomInButtonClicked),
        zoomOut: cen(Events.TasksToolbarZoomOutButtonClicked),
        nextWeek: cen(Events.TasksToolbarNextWeekButtonClicked),
        prevWeek: cen(Events.TasksToolbarPrevWeekButtonClicked),
        nextDay: cen(Events.TasksToolbarNextDayButtonClicked),
        prevDay: cen(Events.TasksToolbarPrevDayButtonClicked),
      },
      bulkActions: {
        responsibleOrgId: cen(Events.TasksToolbarAssignSubcontractor),
        responsible: cen(Events.TasksToolbarAssignResponsible),
        watcher: cen(Events.TasksToolbarAssignWatcher),
        color: cen(Events.TasksToolbarClickColorDropdown),
        setColor: cen(Events.TasksToolbarColorDropdownSetColor),
        resetColor: cen(Events.TasksToolbarColorDropdownResetColor),
        delete: cen(Events.TasksToolbarDeleteButtonClick),
        type: cen(Events.TasksToolbarSelectTaskType),
        location: cen(Events.TasksToolbarSelectTaskLocation),
      },
      colorMode: (mode) => cen(`${Events.TasksToolbarSelect}${toTitleCase(mode)}${Events.ColorMode}`),
      print: cen(Events.TasksToolbarClickPrintButton),
      share: cen(Events.TasksToolbarClickTaskShareButton),
      projectCollabPopup: {
        open: cen(Events.TasksToolbarCollabPopupOpened),
        close: cen(Events.TasksToolbarCollabPopupClosed),
        sendRequest: cen(Events.TasksToolbarCollabPopupSendRequest),
        addNewWorker: cen(Events.TasksToolbarCollabPopupAddNewWorker),
        selectWorker: cen(Events.TasksToolbarCollabPopupSelectWorker),
      },
    },
    [TasksViewMode.gantt]: cen(Events['_ClickLookaheadButtonFromGantt']),
    [TasksViewMode.table]: cen(Events['_ClickLookaheadButtonFromList']),
    collapseTasks: cen(Events['_CollapseAllTasks']),
    expandTasks: cen(Events['_ExpandAllTasks']),
    selectAll: cen(Events.SelectAllRowsCheckbox),
    deselectAll: cen(Events.DeselectAllRowsCheckbox),
    select: cen(Events.SelectActivityRowCheckbox),
    deselect: cen(Events.DeselectActivityRowCheckbox),
    import: {
      loginProCore: cen(Events.ImportLogInProCoreButton),
      logoutProCore: cen(Events.ImportLogOutProCoreButton),
      selectFile: cen(Events.ImportSelectFile),
      button: cen(Events.ImportCSVButton),
      uploadCSVBtn: cen(Events.UploadCSVButton),
      selectDateFormat: cen(Events.ImportDateMapping),
      selectProject: cen(Events.ImportProjectMapping),
      nextBtn: cen(Events.ImportCSVNextButton),
      exitBtn: cen(Events.ImportCSVExitButton),
      backToMapping: cen(Events.BackToMappingButton),
      finishImport: cen(Events.FinishImportingCSVButton),
      completeScreen: cen(Events.ImportCompleteScreen),
      startExport: cen(Events.ImportCSVStartExportButton),
      goToMyTasks: cen(Events.GoToMyTaskButton),
      downloadUpdated: cen(Events.DownloadUpdatedCSVButton),
      needHelpBtn: cen(Events.ImportNeedHelpButton),
      exitCompleteScreen: cen(Events.ImportCompleteExitButton),
      showMoreErrors: cen(Events.ImportShowMoreErrors),
      showAllRows: cen(Events.ImportShowAllRows),
    },
    export: {
      button: cen(Events.LookaheadClickExportFileButton),
      dateFormat: cen(Events.ExportDateFormatButton),
      startExport: cen(Events.StartExportButton),
    },
    taskList: (status) => cen(`${Events.Project}${status}${Events.TaskList}`),
    creteNewTaskEmptyRow: cen(Events.NewTaskEmptyRowClick),
    contextMenu: {
      openMenu: cen(Events.TaskContextMenuOpen),
      closeMenu: cen(Events.TaskContextMenuClose),
      taskDetails: cen(Events.TaskContextMenuOpenActivityDetails),
      addActivity: cen(Events.TaskContextMenuAddActivity),
      addAction: cen(Events.TaskContextMenuAddAction),
      addIssue: cen(Events.TaskContextMenuAddIssue),
      addAssignees: cen(Events.TaskContextMenuAddAssignees),
      share: cen(Events.TaskContextMenuShareButton),
      delete: cen(Events.TaskContextMenuDeleteButton),
    },
    sidePanel: {
      edit: cen(Events.TaskSidePanelClickEdit),
      back: cen(Events.TaskSidePanelClickBack),
      assignees: cen(Events.TaskPanelClickAssigneesTab),
      chat: cen(Events.TaskPanelClickChatTab),
      share: cen(Events.TaskPanelClickShareButton),
      archive: cen(Events.TaskPanelClickArchiveButton),
      delete: cen(Events.TaskPanelClickDeleteButton),
      action: cen(Events.TaskPanelClickAddActionsButton),
      actionCheckbox: cen(Events.TaskPanelClickActionsCheckbox),
      createIssue: cen(Events.SidebarCreateIssueButton),
      deleteIssue: cen(Events.SidebarIssue3dotDeleteClick),
      doneIssue: cen(Events.SidebarIssueCheckboxChecked),
      reopenIssue: cen(Events.SidebarIssueCheckboxUnchecked),
      responsible: cen(Events.TaskPanelClickActionsResponsible),
      finishDate: cen(Events.TaskPanelClickActionsFinishDate),
      issue: cen(Events.TaskPanelClickAddIssuesButton),
      comments: {
        addPhoto: cen(Events.ActivityDetailsCommentTabAddPhoto),
        clickAddCommentButton: cen(Events.GridActivityDetailsCommentTabAddCommentButton),
        clickCalendar: cen(Events.ActivityDetailsCommentTabCalendarPicker),
        flagIssue: cen(Events.ActivityDetailsCommentTabFlagIssue),
        submitComment: cen(Events.ActivityDetailsCommentTabSubmitCommentButton),
        issueMention: cen(Events['IssueDetailsMentionClickToChatRoom']),
        activtiyMention: cen(Events['ActivityDetailsMentionClickToChatRoom']),
      },
    },
    clickAssigneesIcon: cen(Events.ClickAssigneesIcon),
    map: {
      browseFiles: Events.MapBrowseFiles,
      procoreFiles: Events.MapBrowseProfileFiles,
      selectFile: Events.MapSelectNewFile,
      dropFile: Events.MapDropNewFile,
      browseExistingFiles: Events.MapBrowseExistingFilesDropdown,
      selectExistingFile: Events.MapSelectExistingFilesDropdown,
      tools: {
        hand: Events.MapHandButton,
        rectangle: Events.MapRectangleButton,
        polygon: Events.MapPolygonButton,
        polyline: Events.MapPolylineButton,
        line: Events.MapLineButton,
        callout: Events.MapCalloutButton,
        stamp: Events.MapStampButton,
        calloutVisibility: Events.MapCalloutVisibilityButton,
        arrow: Events.MapArrowButton,
        zoomIn: Events.MapZoomOutButton,
        zoomOut: Events.MapZoomOutButton,
        print: Events.MapPrintButton,
        textBox: Events.MapTextBoxButton,
        typewriter: Events.MapTypewriterButton,
      },
      resize: Events.MapResizeDropdown,
      procoreImportPopup: {
        open: cen(Events.ProcoreFileImportPopupOpened),
        close: cen(Events.ProcoreFileImportPopupClosed),
        loggedOut: cen(Events.ProcoreFileImportPopupLoggedOut),
        projectSelected: cen(Events.ProcoreFileImportProjectSelected),
        fileSelected: cen(Events.ProcoreFileImportFileSelected),
        fileImported: cen(Events.ProcoreFileImportFileImported),
      },
      snapshot: {
        newSnapshotButton: cen(Events.NewSnapshotButton),
        newSnapshotPreview: cen(Events.NewSnapshotPreview),
        newSnapshotSubmit: cen(Events.NewSnapshotSubmit),
        savedSnapshotsCopyLink: cen(Events.SavedSnapshotsCopyLink),
        savedSnapshotsDelete: cen(Events.SavedSnapshotsDelete),
        savedSnapshotsPopout: cen(Events.SavedSnapshotsPopout),
      },
    },
  },
  worker: {
    screen: (workerType: WorkerType) => cen(`${workerType}${Events.WorkerProfileScreen}`),
    workerSubmitInviteBtn: (workerType: WorkerType) => cen(`${workerType}${Events.WorkerSubmitInviteButton}`),
    editProfile: (workerType: WorkerType) => cen(`${workerType}${Events.EditProfileButton}`),
    backToWorkers: (workerType: WorkerType) => cen(`${workerType}${Events.BackToWorkersButton}`),
    backToProfile: (workerType: WorkerType) => cen(`${workerType}${Events.BackToProfileButton}`),
    saveInfo: (workerType: WorkerType) => cen(`${workerType}${Events.WorkerSaveInfoButton}`),
    removeWorker: (workerType: WorkerType) => cen(`${workerType}${Events.RemoveWorkerButton}`),
    exit: (workerType: WorkerType) => cen(`${workerType}${Events.ExitNewWorkerButton}`),
    formFields: {
      notifyEmail: (workerType: WorkerType) => cen(`${workerType}${Events.NotifyWithEmailButton}`),
      notifySMS: (workerType: WorkerType) => cen(`${workerType}${Events.NotifyWithSMSButton}`),
      makeAdmin: (workerType: WorkerType) => cen(`${workerType}${Events.MakeAdminButton}`),
      makeForeman: (workerType: WorkerType) => cen(`${workerType}${Events.MakeForemanButton}`),
    },
  },
  workers: {
    searchInput: (workerType: WorkerType) => cen(`${workerType}${Events.WorkersToolbarFocusFilterSearchInput}`),
    screen: (workerType: WorkerType) => cen(`${Events.LandsOn}${workerType}${Events.WorkersScreen}`),
    addWorkerBtn: (workerType: WorkerType) => cen(`${workerType}${Events.WorkerAddButton}`),
    filterDropdown: {
      selected: (workerType: WorkerType, selected: string) =>
        cen(`${workerType}${Events.WorkerToolbarFilter}${selected}${Events.Selected}`),
      button: (workerType: WorkerType) => cen(`${workerType}${Events.FilterButton}`),
    },
  },
  projectCollabLanding: {
    invitedWorker: cen(Events.TasksCollabLandingInvitedWorker),
    openSignedIn: cen(Events.TasksCollabLandingOpenSignedInWorker),
    notSignedIn: cen(Events.TasksCollabLandingNotSignedInWorker),
  },
  signup: {
    page: cen(Events.SignupPage),
    altA: {
      page: cen(Events.AltA_SignupPage),
      buttons: {
        submit: cen(Events.UnbounceLeadMagnetFormSuccess),
        toggleEmail: cen(Events.AltA_SignupEmailToggleButton),
        togglePhone: cen(Events.AltA_SignupPhoneToggleButton),
        emailSubmit: cen(Events.AltA_SignupEmailSubmitButton),
        sendCode: cen(Events.AltA_SignupPhoneSendCodeButton),
        resendCode: cen(Events.AltA_SignupPhoneReSendCodeButton),
        verifyCode: cen(Events.AltA_SignupPhoneVerifyCodeSubmitButton),
      },
    },
    getStarted: {
      page: cen(Events.GetStarted_SignupPage),
      buttons: {
        submit: cen(Events.GetStarted_SignupEmailSubmitButton),
        cancel: cen(Events.GetStarted_SignupEmailCancelButton),
      },
      workerCreated: cen(Events.GetStarted_SignupEmailWorkerCreated),
      companyCreated: cen(Events.GetStarted_SignupEmailCompanyCreated),
      links: {
        policy: cen(Events.GetStarted_SignupEmailUserClicksPolicyLink),
        terms: cen(Events.GetStarted_SignupEmailUserClicksTermsLink),
      },
    },
    steps: {
      first: {
        page: cen(Events.SignupPage),
        buttons: {
          next: cen(Events.SignupStep1NextButton),
          browseImage: cen(Events.SignupStep1BrowseImageButton),
          toggleEmail: cen(Events.SignupStep1EmailToggleButton),
          togglePhone: cen(Events.SignupStep1PhoneToggleButton),
          emailSubmit: cen(Events.SignupStep1EmailSubmitButton),
          sendCode: cen(Events.SignupStep1PhoneSendCodeButton),
          resendCode: cen(Events.SignupStep1PhoneReSendCodeButton),
          verifyCode: cen(Events.SignupStep1PhoneVerifyCodeSubmitButton),
        },
        selectImage: cen(Events.SignupStep1SelectedPhoto),
      },
      second: {
        page: cen(Events.SignupStep2Page),
        buttons: {
          next: cen(Events.SignupStep2NextButton),
        },
      },
      third: {
        page: cen(Events.SignupStep3Page),
        buttons: {
          finish: cen(Events.SignupStep3FinishButton),
          addMyCompany: cen(Events.SignupStep3AddMyCompanyButton),
          submit: cen(Events.SignupStep3AddCompanySubmitButton),
        },
      },
    },
    happyWorkingScreen: {
      showed: cen(Events.AfterSignupInsideC4HappyWorkingScreen),
      buttons: {
        finish: cen(Events.AfterSignupInsideC4HappyWorkingScreenClickFinishButton),
        dismiss: cen(Events.AfterSignupInsideC4HappyWorkingScreenDismiss),
      },
    },
  },
  signin: {
    page: cen(Events.LoginPage),
    buttons: {
      sendCode: cen(Events.LoginSendCodeButton),
      resendCode: cen(Events.LoginPhoneResendCodeButton),
      emailEnter: cen(Events.LoginEmailEnterButton),
      phoneEnter: cen(Events.LoginPhoneEnterButton),
      toggleEmail: cen(Events.LoginEmailToggleButton),
      togglePhone: cen(Events.LoginPhoneToggleButton),
      signupHere: cen(Events.LoginPageClickSignupHereButton),
    },
  },
  inviteSignup: {
    landByEmail: cen(Events.LandOnInviteSignupbyEmailScreen),
    landByPhone: cen(Events.LandOnInviteSignupbyPhoneScreen),
    allSetScreen: cen(Events.LandOnInviteSignupWereAllSetScreen),
    buttons: {
      send: cen(Events.InviteSigupClickSendVerificationCodeButton),
      next: cen(Events.InviteSignupClickNextButton),
      finish: cen(Events.InviteSignupClickFinishButton),
    },
    download: {
      ios: cen(Events.InviteSignupClickAppStoreButton),
      android: cen(Events.InviteSignupClickGoogleStoreButton),
    },
    links: {
      terms: cen(Events.InviteSignupClickTermsLink),
      policy: cen(Events.InviteSignupClickPolicyLink),
    },
  },
  forgotPassword: {
    pages: {
      confirmPassword: cen(Events.ForgotPasswordResetEnterPasswordsPage),
    },
    buttons: {
      forgotPassword: cen(Events.LoginForgotPasswordButtonClick),
      sendResetEmail: cen(Events.ForgotPasswordSendResetEmailButtonClick),
      backToLogin: cen(Events.ForgotPasswordBackToLoginButtonClick),
      resetEmailSent: cen(Events.ForgotPasswordEmailSentBacktoLoginButtonClick),
      emailNotFound: cen(Events.ForgotPasswordEmainNotFoundPage),
      confirmPassword: cen(Events.ForgotPasswordResetPasswordButtonClick),
      loginAfterSubmit: cen(Events.ForgotPasswordResetPasswordConfirmationLoginButtonClick),
    },
    download: {
      ios: cen(Events.ForgotPasswordResetPasswordConfirmationAppStoreButton),
      android: cen(Events.ForgotPasswordResetPasswordConfirmationGooglePlayButton),
    },
  },
  lookahead: {
    addIssue: cen(Events.LookaheadCreateIssue3dotClick),
    timeline: {
      create: cen(Events.LookaheadCanvasClickSetDate),
      delete: cen(Events.LookaheadCanvasClickUnsetDate),
    },
  },
  map: {
    addIssue: cen(Events.VisualizationCreateIssue3dotClick),
  },
  table: {
    addIssue: cen(Events.TableCreateIssue3dotClick),
  },
  issues: {
    collaborate: cen(Events.IssuesViewClickCollaborateButton),
    print: cen(Events.IssuesViewPrintButton),
    dateFilter: cen(Events.IssuesViewDateFilter),
    sidePanel: {
      actions: {
        saveInfo: cen(Events.IssueDetailsSaveIssueInfo),
        goBack: cen(Events.IssueDetailsGoBack),
        linkActivity: cen(Events.IssueDetailsLinkActivitiesButton),
        unlinkActivity: cen(Events.IssueDetailsUnlinkActivitiesButton),
        deleteIssue: cen(Events.IssueDetailsDeleteButton),
      },
    },
  },
  dailies: {
    clickDatePicker: cen(Events.ClickDailiesDatePicker),
    clickDailiesDatePicker: cen(Events.ClickDailiesDatePicker),
    clickAddGeneralComments: cen(Events.ClickAddGeneralComments),
    dailiesGeneralCommentsAttachPhoto: cen(Events.DailiesGeneralCommentsAttachPhoto),
    dailiesGeneralCommentsSubmitButton: cen(Events.DailiesGeneralCommentsSubmitButton),
    dailiesActivityAddReportPopup: cen(Events.DailiesActivityAddReportPopup),
    dailiesActivityPopupEstLaborEdit: cen(Events.DailiesActivityPopupEstLaborEdit),
    dailiesActivityPopupTargetAmount: cen(Events.DailiesActivityPopupTargetAmount),
    dailiesActivityPopupUnitMeasurement: cen(Events.DailiesActivityPopupUnitMeasurement),
    dailiesActivityPopupFlagIssue: cen(Events.DailiesActivityPopupFlagIssue),
    dailiesActivityPopupEnterActualLabor: cen(Events.DailiesActivityPopupEnterActualLabor),
    dailiesActivityPopupEnterProgress: cen(Events.DailiesActivityPopupEnterProgress),
    dailiesActivityPopupEnterComment: cen(Events.DailiesActivityPopupEnterComment),
    dailiesActivityPopupAddPhoto: cen(Events.DailiesActivityPopupAddPhoto),
    dailiesActivityPopupCalendarPicker: cen(Events.DailiesActivityPopupCalendarPicker),
    dailiesActivityPopupSubmitButton: cen(Events.DailiesActivityPopupSubmitButton),
    dailiesActivityReportAddComment: cen(Events.DailiesActivityReportAddComment),
    dailiesActivityReportCreateIssue: cen(Events.DailiesActivityReportCreateIssue),
    dailiesActivityReportClockButton: cen(Events.DailiesActivityReportClockButton),
  },
  gantt: {
    gridActivityProgressButton: cen(Events.GridActivityProgressButton),
    gridActivityAvgLaborButton: cen(Events.GridActivityAvgLaborButton),
    gridActivityCommentButton: cen(Events.GridActivityCommentButton),
    clickIssueIcon: cen(Events.IssueIconClick),
    openSidePanel: cen(Events.OpenSidePanel),
    addIssue: cen(Events.CreateIssue3dotClick),
    enableBaseline: cen(Events.EnableBaseline),
    disableBaseline: cen(Events.DisableBaseline),
    columnTemplateCheck: cen(Events.CheckColumn),
    columnTemplateUncheck: cen(Events.UncheckColumn),
    openLegend: cen(Events.OpenLegendGantt),
    closeLegend: cen(Events.CloseLegendGantt),
    dragBarStartTaskDate: cen(Events.DragBarStartTaskDate),
    dragBarDueTaskDate: cen(Events.DragBarDueTaskDate),
    dragArrowDependency: cen(Events.DragArrowDependency),
    filterDropdown: {
      openFilter: cen(Events.OpenFilterDropdown),
      trackingNumber: cen(Events.FilterByIssueTrackingNumber),
      resolvingCompany: cen(Events.FilterByResolvingCompany),
      liableCompany: cen(Events.FilterByLiableCompany),
      milestonesOnly: cen(Events.FilterByMilestonesOnly),
      status: cen(Events.FilterByStatus),
      responsible: cen(Events.FilterByResponsible),
      locations: cen(Events.FilterByLocation),
      subcontractor: cen(Events.FilterBySubcontractor),
      types: cen(Events.FilterByType),
      issueType: cen(Events.FilterByIssueType),
      impact: cen(Events.FilterByImpact),
      costTrackingNumber: cen(Events.FilterByCostTrackingNumber),
      culpableOrgId: cen(Events.FilterByCulpableOrg),
      costImpact: cen(Events.FilterByCostImpact),
      visibility: {
        showPastDue: cen(Events.FilterByVisibilityPastDueOnly),
        unassignedOnly: cen(Events.FilterByVisibilityUnassignedActivities),
        withIssues: cen(Events.FilterByVisibilityActivityWithIssues),
      },
      applyButton: cen(Events.FilterByApplyButtonClicked),
    },
    inlineEdit: cen(Events.InlineEdit),
  },
  project: {
    editProject: cen(Events.CardEditProjectScreen),
    goToTaskList: cen(Events.GoToTaskListButton),
    backToProjects: cen(Events.BackToProjectsButton),
    save: cen(Events.SaveProjectButton),
    delete: cen(Events.DeleteProjectButton),
    exit: cen(Events.ExitNewProjectButton),
    addNew: cen(Events.AddNewProjectButton),
    newTask: cen(Events.NewTasksButton),
    clone: cen(Events.CloneProjectButton),
    import: {
      button: cen(Events.ProjectPreviewImportButton),
      uploadCSVBtn: cen(Events.ProjectPreviewUploadCSVButton),
      selectDateFormat: cen(Events.ProjectPreviewImportDateMapping),
      selectProject: cen(Events.ProjectPreviewImportMapping),
      nextBtn: cen(Events.ProjectPreviewImportCSVNextButton),
      exitBtn: cen(Events.ProjectPreviewImportCSVExitButton),
      backToMapping: cen(Events.ProjectPreviewBackToMappingButton),
      finishImport: cen(Events.ProjectPreviewFinishImportingCSVButton),
      completeScreen: cen(Events.ProjectPreviewImportCompleteScreen),
      needHelpBtn: cen(Events.ProjectPreviewImportNeedHelpButton),
      startExport: cen(Events.ProjectPreviewImportCSVStartExportButton),
      goToMyTasks: cen(Events.ProjectPreviewGoToMyTaskButton),
      downloadUpdated: cen(Events.ProjectPreviewDownloadUpdatedCSVButton),
      exitCompleteScreen: cen(Events.ProjectPreviewImportCompleteExitButton),
    },
    export: {
      button: cen(Events.ProjectPreviewExportButton),
      dateFormat: cen(Events.ProjectPreviewExportDateFormatButton),
      startExport: cen(Events.ProjectPreviewStartExportButton),
    },
    formFields: {
      selectCountry: cen(Events.SelectCountryButton),
      selectTimezone: cen(Events.SelectTimezoneButton),
    },
  },
  projects: {
    screen: cen(Events.ProjectsScreen),
    newProjectBtn: cen(Events.NewProjectButton),
    // navigation items
    nav: {
      leftNavHeader: cen(Events.ProjectsLeftNavHeader),
      leftNavEditProject: cen(Events.ProjectNameLeftNavEditProjectScreen),
      viewActiveTasksList: cen(Events.ProjectViewTasksActiveTaskList),
      chatButtonClick: cen(Events.SideNavChatButtonClick),
    },
    buttons: {
      cardTasks: cen(Events.ProjectCardTasksButton),
      questionMark: cen(Events.QuestionMarkButton),
      createMyProject: cen(Events.QuestionMarkButton),
      searchProject: cen(Events.ProjectsScreenSearchButton),
      newProjectCard: cen(Events.NewProjectCard),
    },
  },
  reports: {
    screen: cen(Events.ReportsScreen),
    goToTask: cen(Events.ReportsGoToTaskButton),
    timeFrame: cen(Events.ReportsTimeFrameButton),
    projectSelection: cen(Events.ReportsProjectSelectionButton),
    goToProjectTasks: cen(Events.ReportsGoToProjectTasksButton),
    showPastDue: cen(Events.ShowPastDueTasksButton),
    showWithIssues: cen(Events.ShowTasksWithIssuesButton),
    showWorkers: cen(Events.ShowWorkersButton),
  },
  company: {
    createCompany: cen(Events.ButtonClickCreateCompanyExistingC4User),
    createProfile: cen(Events.ButtonClickCreateProfileNewCompanyExistingC4User),
    createYourOwnCompany: cen(Events.ButtonClickCreateYourOwnCompanyExistingC4User),
    saveNewCompany: cen(Events.ButtonClickSaveNewCompanyExistingC4User),
  },
  common: {
    buttons: {
      haveQuestions: cen(Events.HaveQuestionsButton),
      bookDemo: cen(Events.BookDemo),
    },
  },
  projectOrgs: {
    toolbar: {
      searchInput: cen(Events.ProjectOrgsToolbarSearchInputFocus),
      createOrg: cen(Events.ProjectOrgsToolbarCreateCompanyButton),
    },
    screen: cen('LandsOnProjectOrgsScreen'),
    save: (action) => `${prfx}${Events.ProjectOrgs}${action}${Events.CompanySaveButton}`, // action is Create/Edit
    close: (action) => `${prfx}${Events.ProjectOrgs}${action}${Events.CompanyCloseButton}`, // action is Create/Edit
    selectResponsible: cen(Events.ProjectOrgSelectResponsible),
    selectColor: cen(Events.ProjectOrgSelectColor),
    goToWorkersPage: cen(Events.ProjectOrgEditGoToWorkersButton),
    editOrg: cen(Events.ProjectOrgsEditOrgProfile),
    deleteOrg: cen(Events.ProjectOrgsDelete),
  },
  profile: {
    open: cen(Events.ProfileOpenedForm),
    close: cen(Events.ProfileClosedForm),
    saveChanges: cen(Events.ProfileSaveChanges),
  },
} as const;

/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/naming-convention */
import {Effect, Layer} from 'effect';
import {InterruptedException} from 'effect/Cause';

import {LoadOptions, Activity} from 'modules/Tasks/components/Gantt/types';
import {transformIssuesData} from 'modules/Tasks/components/Gantt/utils/functions';
import {IssueModelRawDTO, TaskGanttModel, GanttLinkModelDTO} from 'shared/models/task/task';

import {createFetchIssuesBatch, createFetchTaskDetailsBatch} from './api/createIssueEffect';
import {BatchLoadConfig, BatchLoader} from './BatchLoader';
import {EffectError} from './errors';
import {createProjectLoader} from './helpers';

export type IssuesLoaderResult = {
  allIssues: IssueModelRawDTO[];
  ganttData: {
    tasks: TaskGanttModel[];
    links: GanttLinkModelDTO[];
  };
  taskIdToNameMap: Map<string, string>;
};

const effect = Effect.gen(function* () {
  const batchLoader = yield* BatchLoader;

  const loadIssues = ({
    queryParams,
    projectId,
    dataRange,
    done,
    setLoading,
  }: LoadOptions): Effect.Effect<IssueModelRawDTO[] | InterruptedException, EffectError, never> => {
    // Create the loading effect
    const loadingEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(true);
      }
    });

    // Create the cleanup effect
    const cleanupEffect = Effect.sync(() => {
      if (setLoading) {
        setLoading(false);
      }
    });

    const fetchIssuesBatch = createFetchIssuesBatch({
      projectId,
      queryParams,
      dataRange,
    });

    const fetchAndTransformData = Effect.gen(function* (_) {
      const allIssues = yield* batchLoader.createBatchLoadEffect({
        batchFn: fetchIssuesBatch,
        fnName: 'loadIssues',
        projectId,
      });

      const ganttData = transformIssuesData({
        tasks: allIssues,
        projectId,
        flatList: false,
        feedbackMap: new Map(),
      });

      const allTaskIds = new Set<string>();
      ganttData.tasks.forEach((issue) => issue.task_ids.forEach((taskId) => allTaskIds.add(taskId)));
      const taskIdsList = Array.from(allTaskIds);

      const fetchTaskDetailsBatch = createFetchTaskDetailsBatch({
        taskIdsList,
      });

      const taskDetailsResults = yield* batchLoader.createBatchLoadEffect({
        batchFn: fetchTaskDetailsBatch,
        fnName: 'loadTaskDetails',
        projectId,
      });

      const taskIdToNameMap = new Map<string, string>();
      taskDetailsResults?.forEach((task) => taskIdToNameMap.set(task.id, task.name));

      const tasks = ganttData.tasks.map((issue) => ({
        ...issue,
        activities: issue.task_ids.reduce((acc, taskId) => {
          const taskName = taskIdToNameMap.get(taskId);
          if (taskName) {
            acc.push({id: taskId, name: taskName});
          }
          return acc;
        }, [] as Activity[]),
      }));

      done({links: ganttData.links, tasks});
      return allIssues;
    });

    return loadingEffect.pipe(
      Effect.flatMap(() => createProjectLoader(projectId, fetchAndTransformData)),
      Effect.ensuring(cleanupEffect),
    );
  };

  return {
    loadIssues,
  } as const;
});

export class ConcurrentIssuesLoader extends Effect.Service<ConcurrentIssuesLoader>()('ConcurrentIssuesLoader', {
  effect,
  dependencies: [BatchLoader.Default],
}) {
  static Test = <T>(mockData?: T[]) => {
    // For success cases with mock data
    if (Array.isArray(mockData) || (mockData && 'tasks' in mockData)) {
      return this.DefaultWithoutDependencies.pipe(
        Layer.provide(
          Layer.succeed(BatchLoader, {
            createBatchLoadEffect: <U>(_config: BatchLoadConfig<U>): Effect.Effect<U[], Error, never> =>
              Effect.succeed(Array.isArray(mockData) ? (mockData as unknown as U[]) : ([mockData] as unknown as U[])),
            _tag: 'BatchLoader' as const,
          }),
        ),
      );
    }

    // Default case for error testing
    const defaultBatchLayer = Layer.succeed(BatchLoader, {
      createBatchLoadEffect: <T>(config: BatchLoadConfig<T>): Effect.Effect<T[], Error, never> => {
        return Effect.gen(function* () {
          const response = yield* config.batchFn(0, config.batchSize, null);
          return response.data;
        });
      },
      _tag: 'BatchLoader' as const,
    });

    return this.DefaultWithoutDependencies.pipe(Layer.provide(defaultBatchLayer));
  };
}

import cn from 'classnames';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';

import IconSpriteSVG from 'assets/images/svg-sprite.svg';
import SpriteSVG from 'assets/images/svg-statuses.svg';
import {getToggleIcon} from 'modules/Tasks/components/Gantt/hooks/useRowsOpenState';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {getTaskColor, getTaskOpenIssuesIds} from 'modules/Tasks/utils/functions';
import {GANTT_PREFERENCES_KEY} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {getPrettyTaskStatuses, taskStatusIcons} from 'shared/constants/statuses';
import {addDays, ISO_SHORT, safeFormatDate, safeParseDate, subtract} from 'shared/helpers/dates';
import {getLocalStorageValue} from 'shared/hooks/useLocalStorage';
import {QueryCacheHelper} from 'shared/hooks/useQueryCache/QueryCacheHelper';
import {ProjectModel} from 'shared/models/project';
import {TaskObjectType, TaskObjectSubType} from 'shared/models/task/const';
import {TaskColorMode, TaskGanttModel, WakeCapCrews, WakeCapZones} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {isBaseLineMode} from '../../../Views/Gantt/utils/baselineHandlers';

import {getGanttColumnsLabels, GANTT_COLUMNS_NAMES, GanttZoomLevels} from './constants';
import {validateDueDate} from './date';
import {setHolidays, setProjectCalendarExceptions, getOneDayFilterOptions} from './functions';
import {formatTaskDependencies, FOURTH_LEVEL, getGridRowClass, isPlaceholderTask, markWbsDatesDisabled} from './gantt';
import ganttClasses from './ganttColumns.module.scss';
import {configureInlineEditors} from './inlineEditors';

interface ConfigureGanttBaseConfig {
  gantt: GanttStatic;
  t: TFunction;
  projectId: string;
  cacheHelper: QueryCacheHelper;
  hasAdminRole?: boolean;
}

export const IconTypeMap = {
  EditIcon: 'edit',
  CommentsIcon: 'comments',
} as const;

type EditIconType = (typeof IconTypeMap)[keyof typeof IconTypeMap];

export function configureGanttBase({gantt, t, projectId, cacheHelper, hasAdminRole = true}: ConfigureGanttBaseConfig) {
  gantt.plugins({marker: true, undo: true, multiselect: true, zoom: true, keyboard_navigation: true, tooltip: true});
  gantt.config.keyboard_navigation = true;
  gantt.config.keyboard_navigation_cells = true;
  gantt.config.sort = true;
  gantt.config.root_id = '0';
  gantt.config.row_height = 42;
  gantt.config.task_attribute = 'data-task-id';
  gantt.config.drag_progress = false;
  gantt.config.preserve_scroll = true;
  gantt.config.initial_scroll = false;
  gantt.config.show_unscheduled = true;
  gantt.config.fit_tasks = true;
  gantt.config.placeholder_task = true;
  gantt.config.show_tasks_outside_timescale = true;
  gantt.config.scroll_on_click = false;
  gantt.config.scale_offset_minimal = true;
  gantt.config.min_column_width = 45;
  gantt.config.drag_links = true;
  gantt.config.multiselect = true;
  gantt.config.drag_multiple = false;
  gantt.config.scale_height = 48;
  gantt.config.reorder_grid_columns = true;
  gantt.config.date_grid = '%m/%d/%y';
  gantt.config.touch = true;
  gantt.config.touch_drag = 750;
  configureTimeline(gantt, projectId);
  configureTemplates(gantt);
  configureInlineEditors(gantt, t, cacheHelper);
  configureTooltip(gantt, t);
  // turn on tree features
  gantt.config.order_branch = 'marker';
  gantt.config.order_branch_free = false;
  gantt.config.branch_loading = true;

  gantt.templates.format_date = function (date) {
    return safeFormatDate(date, ISO_SHORT);
  };
  gantt.templates.grid_date_format = function (date) {
    return date ? safeFormatDate(date) : '';
  };

  gantt.config.undo_types = {
    task: 'task',
  };

  gantt.config.undo_actions = {
    update: 'update',
    remove: 'remove',
    add: 'add',
    move: 'move',
  };

  gantt.getColumnLabels = getGanttColumnsLabels;
  gantt.config.columns = getColumns(gantt, t, hasAdminRole, cacheHelper);
  gantt.taskColorMode =
    getLocalStorageValue(GANTT_PREFERENCES_KEY, `byProject.${projectId}.colorTasksMode`) || TaskColorMode.COMPANY;
}

function configureTooltip(gantt: GanttStatic, t: TFunction) {
  const eventId = gantt.attachEvent(
    'onGanttReady',
    () => {
      gantt.ext.tooltips.detach('[' + gantt.config.task_attribute + ']:not(.gantt_task_row)');
      gantt.ext.tooltips?.tooltipFor({
        global: true,
        selector: '.gantt_grid_head_cell',
        onmouseleave: () => gantt.ext.tooltips.tooltip.hide(),
        html: (_event, node: HTMLElement) => {
          const tooltips = {
            gantt_grid_head_estimated_labor_abbr: t('gantt:columns_tooltips.estimated_labor'),
            gantt_grid_head_average_labor_abbr: t('gantt:columns_tooltips.average_labor'),
            gantt_grid_head_progress: t('gantt:columns_tooltips.progress'),
            gantt_grid_head_zone: t('gantt:columns_tooltips.zone'),
            gantt_grid_head_crew: t('gantt:columns_tooltips.crew'),
          };
          for (const className in tooltips) {
            if (node.classList.contains(className)) {
              const tooltip = gantt.ext.tooltips.tooltip.getNode();
              tooltip.classList.add('header_tooltip');
              return `
                <p class="header_tooltip_text">${tooltips[className]}</p>
              `;
            }
          }
        },
      });
      gantt.detachEvent(eventId);
    },
    undefined,
  );
}

export function getColumnEditors() {
  return {
    nameEditor: getGeneralEditor(GANTT_COLUMNS_NAMES.name),
    actualEndDateEditor: {type: 'actualEndDateEditor', map_to: GANTT_COLUMNS_NAMES.actualEnd},
    actualStartDateEditor: {type: 'actualStartDateEditor', map_to: GANTT_COLUMNS_NAMES.actualStart},
    crewEditor: {type: 'crewEditor', map_to: GANTT_COLUMNS_NAMES.crew},
    durationEditor: {type: 'durationEditor', map_to: GANTT_COLUMNS_NAMES.duration},
    endDateEditor: {type: 'endDateEditor', map_to: GANTT_COLUMNS_NAMES.endDate},
    locationEditor: {type: 'locationEditor', map_to: GANTT_COLUMNS_NAMES.location},
    predecessorEditor: {type: 'predecessorEditor', map_to: 'auto'},
    responsibleEditor: {type: 'responsibleEditor', map_to: GANTT_COLUMNS_NAMES.responsible},
    startDateEditor: {type: 'requiredDate', map_to: GANTT_COLUMNS_NAMES.startDate},
    statusEditor: {type: 'statusEditor', map_to: GANTT_COLUMNS_NAMES.taskStatus},
    subcontractorEditor: {type: 'subcontractorEditor', map_to: GANTT_COLUMNS_NAMES.subcontractor},
    taskTypeEditor: {type: 'taskTypeEditor', map_to: GANTT_COLUMNS_NAMES.type},
    zoneEditor: {type: 'zoneEditor', map_to: GANTT_COLUMNS_NAMES.zone},
  } satisfies Record<string, {type: string; map_to: string}>;
}

function getGeneralEditor(mapTo: GANTT_COLUMNS_NAMES) {
  return {type: 'generalEditor', map_to: mapTo};
}

function configureTemplates(gantt: GanttStatic) {
  const wbsColor = (task) => `wbs-level__${task.$level > FOURTH_LEVEL ? FOURTH_LEVEL : task.$level}`;
  gantt.templates.task_text = (start, end, task) => {
    if (task.object_type === TaskObjectType.summary) return '';
    const prefColor = getTaskColor(gantt, task);
    if (prefColor) {
      return `<div style="background-color: ${prefColor}; border-radius: 5px; color: #FFFFFF">${
        task.abbrev || task.name
      }</div>`;
    }
    return task.abbrev || task.name;
  };
  gantt.templates.rightside_text = function (start, end, task: GanttTask) {
    const openIssuesIds = getTaskOpenIssuesIds(task.status_issue_task_ids_pairs);
    return [TaskObjectType.task, TaskObjectType.task].includes(task.object_type) && openIssuesIds.length
      ? `<span class="gantt__badge ${cn({
          gantt__badge_offset: !!task.$source?.length,
          gantt__badge_baselineMode: isBaseLineMode(),
        })}">${openIssuesIds.length}</span>`
      : '';
  };
  gantt.templates.grid_open = (task: GanttTask) => {
    return "<div class='gantt_tree_icon gantt_" + (task.$open ? 'close' : 'open') + "'></div>";
  };
  gantt.templates.grid_file = () => '';
  gantt.templates.grid_folder = () => '';
  gantt.templates.grid_blank = () => '';
  gantt.templates.grid_row_class = getGridRowClass(gantt);
  gantt.templates.task_row_class = (start, end, task) => {
    return `gantt_task_row--${task.object_type} ${task.object_type === TaskObjectType.summary ? wbsColor(task) : ''}`;
  };

  gantt.templates.grid_indent = function () {
    return "<div class='gantt__indent'></div>";
  };

  gantt.templates.link_description = function (link) {
    const from = gantt.getTask(link.source);
    const to = gantt.getTask(link.target);
    const types = gantt.config.links;

    const fromStart = link.type == types.start_to_start;
    const toStart = link.type == types.finish_to_start || link.type == types.start_to_start;
    let text = `From:<b> ${from.name}</b> (${fromStart ? 'Start' : 'End'}) <br/>`;
    text += `To:<b> ${to?.name}</b> (${toStart ? 'Start' : 'End'}) <br/>`;
    return text;
  };

  gantt.templates.drag_link = function (from: string, fromStart: boolean, to: string, toStart: boolean) {
    let text = `From:<b> ${gantt.getTask(from).name}</b> (${fromStart ? 'Start' : 'End'}) <br/>`;
    if (to) {
      text += `To:<b> ${gantt.getTask(to)?.name}</b> (${toStart ? 'Start' : 'End'}) <br/>`;
    }
    return text;
  };

  gantt.templates.parse_date = function (date: string) {
    return safeParseDate(date);
  };

  gantt.templates.tooltip_date_format = function (date) {
    return safeFormatDate(date);
  };

  gantt.templates.date_grid = function (date, task: GanttTask, column) {
    if (isPlaceholderTask(gantt, task)) return;
    if (!task[column]) return 'N/A';
    if ([GANTT_COLUMNS_NAMES.actualEnd, GANTT_COLUMNS_NAMES.endDate].includes(column as GANTT_COLUMNS_NAMES)) {
      const showAsDue = column === GANTT_COLUMNS_NAMES.endDate && validateDueDate(task);
      const endDateColumn = gantt.templates.grid_date_format(addDays(date, -1).toDate(), column);
      return `<div class="${showAsDue ? 'date-info--status-fail' : ''}" >${endDateColumn}</div>`;
    }
    return gantt.templates.grid_date_format(date, column);
  };

  gantt.templates.task_class = function (startDate, endDate, task: TaskGanttModel): string {
    const classes = [`gantt_task_line__${task.object_type}-type`];
    if (task.object_type === TaskObjectType.milestone) return classes.join(' ');
    if (task.object_type === TaskObjectType.summary) {
      classes.push('prevent-move prevent-resize-start prevent-resize-end');
    }
    return classes.join(' ');
  };

  gantt.templates.grid_header_class = function (columnName, column) {
    return cn({gantt_grid_head_resizable: column.resize});
  };
}

export function configureGanttWorkTime(gantt: GanttStatic, project: ProjectModel, projectId: string) {
  const calendarId = gantt.addCalendar({id: projectId});
  const calendar = gantt.getCalendar(calendarId);
  calendar.setWorkTime({hours: [`${project?.defaultTaskStart}-${project?.defaultTaskEnd}`]});

  if (project) {
    setHolidays(project.calendar.workDays, calendar);
    setProjectCalendarExceptions(gantt, project);
  }
}

export const configureTimeline = (
  gantt: GanttStatic,
  projectId: string,
  getCellClass?: (task: GanttTask, date: Date) => string,
) => {
  gantt.config.scales = getDayScale(gantt, projectId);
  gantt.templates.timeline_cell_class = (task: GanttTask, date: Date) => {
    let cellClass = '';
    const calendar = gantt.getCalendar(projectId);
    if (gantt.ext?.zoom?.getLevels()) {
      if (gantt.ext.zoom.getLevels()[gantt.ext.zoom.getCurrentLevel()]?.name !== GanttZoomLevels.DAY) {
        return cellClass;
      }
    }

    if (calendar && !calendar.isWorkTime(date)) {
      cellClass += 'weekend';
    }

    return getCellClass ? `${cellClass} ${getCellClass(task, date)}` : cellClass;
  };
};

export function getIssuesBadge(issueCount: number, classname: string) {
  const badge = document.createElement('span');
  badge.innerText = `${issueCount}`;
  badge.className = classname;
  return badge;
}

export function getDayScale(gantt: GanttStatic, projectId: string) {
  return [
    {unit: 'month', step: 1, format: '%F, %Y'},
    {
      unit: 'day',
      step: 1,
      format: '%j',
      css: function (date) {
        const calendar = gantt.getCalendar(projectId);
        if (calendar) {
          const firstWeekend = !calendar.isWorkTime(date);
          const secondWeekend = !calendar.isWorkTime(dayjs(date).add(1, 'day').toDate());
          const yesterdayWeekend = !calendar.isWorkTime(dayjs(date).add(-1, 'day').toDate());
          const isToday = dayjs(date).isSame(new Date(), 'day');
          const isOneDayFilter = checkEnableOneDayFilter(date) ? ' is-one-day-filter' : '';
          const isDayClass = ` is-day ${isToday ? 'is-today' : ''}`;
          // TODO: simplify
          if (firstWeekend && secondWeekend) return 'weekend first-weekend' + isDayClass + isOneDayFilter;
          if (firstWeekend && !secondWeekend && !yesterdayWeekend)
            return 'weekend only-one-weekend' + isDayClass + isOneDayFilter;
          if (firstWeekend) return 'weekend second-weekend' + isDayClass + isOneDayFilter;
          return isDayClass + isOneDayFilter;
        }
      },
    },
  ];
}

function checkEnableOneDayFilter(currentDay: Date) {
  const {filterDate, enableOneDayFilter} = getOneDayFilterOptions();
  if (enableOneDayFilter) {
    return dayjs(currentDay).isSame(filterDate, 'day');
  }
  return false;
}

function getActualizeStartCheck(task: TaskGanttModel, disabled = false): string {
  if (
    task.object_type === TaskObjectType.activity ||
    (task.object_type === TaskObjectType.milestone && task.object_subtype === TaskObjectSubType.start)
  ) {
    return getActualizeCheck(!!task.actual_start, disabled);
  }
  return '';
}

function getActualizeEndCheck(task: TaskGanttModel, disabled = false): string {
  if (
    task.object_type === TaskObjectType.activity ||
    (task.object_type === TaskObjectType.milestone && task.object_subtype === TaskObjectSubType.end)
  ) {
    return getActualizeCheck(!!task.actual_end, disabled);
  }
  return '';
}

function getActualizeCheck(actualized: boolean, disabled = false): string {
  return `<svg class="${cn(
    'gantt__actual-check',
    disabled && 'gantt__actual-check_disabled',
    actualized ? 'gantt__actual-check_actualized' : 'gantt__actual-check_unactualized',
  )}" width="24" height="24">
    <title>${actualized ? 'Deactualize' : 'Actualize'}</title>
    <use xlink:href="${IconSpriteSVG}#${actualized ? 'checkmark-circle' : 'checkmark-circle-outline'}" />
  </svg></div>`;
}

function getIssueIcon(task: GanttTask): string {
  const openIssues = task?.status_issue_task_ids_pairs?.filter((pair) => {
    return pair.issue_task_status !== TaskStatusType.closed && pair.issue_task_ids.length;
  });
  if (openIssues?.length) {
    return `
      <svg data-issue-icon=true class="${cn(ganttClasses.pointer, ganttClasses.icon, ganttClasses.icon_color_fill)}">
        <title>${task.issue_task_ids.length > 1 ? 'Show Issues' : 'Show Issue'}</title>
        <use xlink:href="${IconSpriteSVG}#clockdelay"} />
      </svg>
    `;
  }
  return '';
}

function getRiskIcon(gantt: GanttStatic, task: GanttTask): string {
  return `
    <span data-risk-icon=true class="risk-icon ${cn(ganttClasses.pointer)}">
      ${gantt.statusIcon?.getHasFlagsIconHTML(task)}
    </span>
  `;
}

export const getColumnIcon = (editIconType: EditIconType = IconTypeMap.EditIcon, issue = '') =>
  editIconType === IconTypeMap.EditIcon
    ? `
  <svg width="16" height="16" class="edit_icon" data-${editIconType}-icon=true>
    <use xlink:href="${IconSpriteSVG}#edit-pencil" />
  </svg>
  `
    : `
  <svg width="16" height="16" class="edit_icon" data-${editIconType}${issue ? `-${issue}` : ''}-icon=true>
    <use xlink:href="${IconSpriteSVG}#comment-vs" fill="#053FE3" fill-opacity="0.7" />
  </svg>
`;

const getLabelWithInfoIcon = (label: GANTT_COLUMNS_NAMES, labels: Record<string, string>) => {
  return `
    <span data-column-name="${label}">
      <span data-replacer>${labels[label]}</span>
      <svg class="info_icon">
        <use xlink:href="${IconSpriteSVG}#info_outlined" />
      </svg>
    </span>
  `;
};

interface GanttColumn {
  name: GANTT_COLUMNS_NAMES;
  label: string;
  align: string;
  width: number;
  resize: boolean;
  editor: {map_to: GANTT_COLUMNS_NAMES | string; type: string};
  template: (model: any) => string;
  hide: boolean;
  attrs?: {
    pattern?: string;
    maxlength?: number;
    minlength?: number;
    type?: string;
  };
  validate?: {
    min?: string;
    max?: string;
  };
  order: number;
}

export function generateColumn(
  {
    name,
    label,
    editor = getGeneralEditor(name),
    align = 'left',
    width = 100,
    resize = true,
    hide = false,
    template,
    ...rest
  }: Partial<GanttColumn>,
  labels: Record<string, string> | null = null,
) {
  return {
    name,
    label: label || labels[name],
    align,
    width,
    resize,
    editor,
    hide,
    template,
    ...rest,
  };
}

function getColumns(gantt: GanttStatic, t: TFunction, hasAdminRole = true, cacheHelper: QueryCacheHelper) {
  const editors = getColumnEditors();
  const labels = gantt.getColumnLabels(t);
  const activityLabel = t('gantt:activity.placeholder', 'Click to View Details');
  return [
    {
      name: GANTT_COLUMNS_NAMES.checkbox,
      hide: false,
      width: 45,
      resize: false,
      label: `<div class="gantt__custom_checkbox">
                <input class="custom_checkbox_id" type="checkbox"/>
                <label class="gantt__custom_checkbox_label"></label>
              </div>`,
      template: (task: GanttTask) => `
              <button class="gantt__checkbox">
              ${
                !gantt.isSelectedTask(task.id)
                  ? `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="gantt__checkbox_uncheck">
                  <rect x="0.5" y="0.5" width="15" height="15" rx="4.5" stroke="#A5A4A4"/>
                </svg>`
                  : `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="gantt__checkbox_check">
                  <rect x="4.5" y="4.5" width="15" height="15" rx="4.5" fill="#053FE3" stroke="#053FE3"/>
                  <path d="M9.5 12L11.5 14.5L15 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
              }
            </button>`,
    },
    {
      name: GANTT_COLUMNS_NAMES.rownumber,
      label: labels[GANTT_COLUMNS_NAMES.rownumber],
      resize: true,
      align: 'left',
      width: 45,
      hide: false,
      template: (task: GanttTask) => {
        return task.type !== 'placeholder' ? task.rownum : '';
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.uniqueId,
      label: labels[GANTT_COLUMNS_NAMES.uniqueId],
      align: 'left',
      resize: true,
      width: 100,
      hide: true,
    },
    {
      name: GANTT_COLUMNS_NAMES.icons,
      label: labels[GANTT_COLUMNS_NAMES.icons],
      resize: true,
      width: 80,
      hide: false,
      template: (task: GanttTask) => {
        return `
          <div class="gantt__task-icons">
            ${getIssueIcon(task)}
            ${getRiskIcon(gantt, task)}
          </div>
        `;
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.name,
      label: `
        <span data-column-name="${GANTT_COLUMNS_NAMES.name}">
          <button class="gantt-grid_head_collapse" data-column-name="${
            GANTT_COLUMNS_NAMES.name
          }" id="gantt-collapse-button" data-all-collapsed="${gantt.allCollapsed ?? ''}">
          ${typeof gantt.allCollapsed === 'boolean' ? getToggleIcon(gantt.allCollapsed) : ''}
          </button>
          <span data-replacer>${labels[GANTT_COLUMNS_NAMES.name]}</span>
        </span>
      `,
      align: 'left',
      tree: true,
      hide: false,
      resize: true,
      width: 250,
      editor: editors.nameEditor,
      template: (task: GanttTask) => {
        if (isPlaceholderTask(gantt, task)) {
          return `<div class="gantt_task-placeholder">${t(
            'gantt:inlineAddPlaceholder',
            '+ Click here to add new activity',
          )}</div>`;
        }
        return `
          <div class="gantt__task-name">
            <p data-cy="gantt-activity-item" class="gantt__task-name_link ${
              !task.name ? 'gantt-edit__empty-field' : ''
            }">${task.name}</p>
            ${task.isPending ? '<div class="spinner"></div>' : ''}
            <div class="gantt__task-name_open_wrap" title="${activityLabel}">
                <svg class="gantt__task-name_open" width="24" height="24" style="${
                  task?.time_removed ? 'display: none' : ''
                }" fill="#A5A4A4"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM13 12.5C13 12.2239 12.7761 12 12.5 12H11.5C11.2239 12 11 12.2239 11 12.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V12.5ZM12.5 8C12.7761 8 13 8.22386 13 8.5V9.5C13 9.77614 12.7761 10 12.5 10H11.5C11.2239 10 11 9.77614 11 9.5V8.5C11 8.22386 11.2239 8 11.5 8H12.5Z"/></svg>
            </div>
          </div>`;
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.duration,
      label: labels[GANTT_COLUMNS_NAMES.duration],
      align: 'left',
      width: 45,
      resize: true,
      hide: false,
      template: (task: TaskGanttModel) => {
        if (task.datesIsPristine || task.taskDuration === undefined) return '';
        return task.taskDuration + 'd';
      },
      editor: editors.durationEditor,
    },
    {
      name: GANTT_COLUMNS_NAMES.predecessor,
      label: labels[GANTT_COLUMNS_NAMES.predecessor],
      align: 'left',
      width: 45,
      resize: true,
      hide: false,
      template: (task: GanttTask) => {
        if (task.type === 'placeholder') return '';
        if (task?.meta?.predecessor || task.isPending) return 'Saving...';
        return formatTaskDependencies(gantt, task);
      },
      editor: editors.predecessorEditor,
    },
    {
      name: GANTT_COLUMNS_NAMES.startDate,
      label: labels[GANTT_COLUMNS_NAMES.startDate],
      align: 'left',
      width: 125,
      resize: true,
      editor: editors.startDateEditor,
      template: (task: TaskGanttModel) => {
        if (task?.meta?.predecessor) return 'Updating...';
        if (task.datesIsPristine || (task.object_type === TaskObjectType.milestone && task.object_subtype === 'end'))
          return '';
        const template = gantt.templates.grid_date_format(task.start_date, GANTT_COLUMNS_NAMES.startDate);
        return template + getActualizeStartCheck(task, !hasAdminRole);
      },
      hide: false,
      onrender: markWbsDatesDisabled,
    },
    {
      name: GANTT_COLUMNS_NAMES.endDate,
      label: labels[GANTT_COLUMNS_NAMES.endDate],
      align: 'left',
      width: 125,
      editor: editors.endDateEditor,
      validate: {
        min: GANTT_COLUMNS_NAMES.startDate,
      },
      template: (task: TaskGanttModel) => {
        if (task?.meta?.predecessor) return 'Updating...';
        if (task.datesIsPristine || (task.object_type === TaskObjectType.milestone && task.object_subtype === 'start'))
          return '';
        const template = gantt.templates.grid_date_format(
          subtract(task.end_date, 1).toDate(),
          GANTT_COLUMNS_NAMES.endDate,
        );
        return template + getActualizeEndCheck(task, !hasAdminRole);
      },
      hide: false,
      resize: true,
      onrender: markWbsDatesDisabled,
    },
    {
      name: GANTT_COLUMNS_NAMES.responsible,
      align: 'left',
      label: labels[GANTT_COLUMNS_NAMES.responsible],
      width: 130,
      hide: false,
      resize: true,
      editor: editors.responsibleEditor,
      template: (task: TaskGanttModel) => {
        if (task && task.object_type !== TaskObjectType.summary) {
          if (task.responsible?.length) {
            return task.responsible[0].member_name;
          }
          return `<div>${t('gantt:responsible.unassigned', 'Unassigned')}</div>`;
        }
        return '';
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.assignmentCount,
      align: 'left',
      label: labels[GANTT_COLUMNS_NAMES.assignmentCount],
      width: 100,
      hide: true,
      resize: true,
      template: (task: TaskGanttModel) => {
        const icon = `
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.83306 14.3339C7.54824 12.9035 9.01017 12 10.6094 12H13.3906C14.9898 12 16.4518 12.9035 17.1669 14.3339C18.0091 16.0182 16.7843 18 14.9012 18H9.09884C7.21569 18 5.99089 16.0182 6.83306 14.3339Z" fill="#A5A4A4"/>
                  <circle cx="12" cy="8" r="3" fill="#A5A4A4"/>
                  </svg>
        `;
        return `<div class="gantt__assigners-icon ${
          task?.assignment_count ? 'gantt__assigners-icon--empty' : ''
        }">${icon} ${task?.assignment_count || 'Add'}</div>`;
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.description,
      label: labels[GANTT_COLUMNS_NAMES.description],
      align: 'left',
      width: 200,
      resize: true,
      hide: false,
      template: (task: TaskGanttModel) => `<div title="${task.description}">${task.description || ''}</div>`,
      editor: {type: 'generalEditor', map_to: GANTT_COLUMNS_NAMES.description},
    },
    {
      name: GANTT_COLUMNS_NAMES.taskStatus,
      label: labels[GANTT_COLUMNS_NAMES.taskStatus],
      hide: true,
      width: 140,
      resize: true,
      editor: editors.statusEditor,
      template: (task) => {
        const status = task.status || task.taskStatus;
        const taskXlink = `${SpriteSVG}#${taskStatusIcons[status] || status}`;
        return `<div class="status-element status-element--tba">
                  <svg class="icon icon--color-fill status-element__icon" width="12" height="12">
                      <use xlink:href=${taskXlink}>
                  </svg>
                  <span class="status-element__name" style="font-family: Graphik LCG,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif; font-weight: 400">
                    ${getPrettyTaskStatuses(t)[status]}
                  </span>
            </div>`;
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.subcontractor,
      label: labels[GANTT_COLUMNS_NAMES.subcontractor],
      align: 'left',
      template: function (task) {
        if (task.responsible_org?.group?.name) {
          return task.responsible_org.group.name;
        }
        if (task?.responsible_party) {
          return task.resposible_party;
        }
        return '';
      },
      width: 140,
      resize: true,
      hide: false,
      editor: editors.subcontractorEditor,
    },
    {
      name: GANTT_COLUMNS_NAMES.location,
      label: labels[GANTT_COLUMNS_NAMES.location],
      align: 'left',
      width: 100,
      hide: true,
      resize: true,
      editor: editors.locationEditor,
    },
    {
      name: GANTT_COLUMNS_NAMES.type,
      label: labels[GANTT_COLUMNS_NAMES.type],
      align: 'left',
      width: 140,
      resize: true,
      hide: true,
      editor: editors.taskTypeEditor,
    },
    {
      name: GANTT_COLUMNS_NAMES.actualStart,
      label: labels[GANTT_COLUMNS_NAMES.actualStart],
      align: 'left',
      width: 100,
      resize: true,
      editor: editors.actualStartDateEditor,
      hide: true,
      onrender: markWbsDatesDisabled,
    },
    {
      name: GANTT_COLUMNS_NAMES.actualEnd,
      label: labels[GANTT_COLUMNS_NAMES.actualEnd],
      align: 'left',
      width: 100,
      resize: true,
      editor: editors.actualEndDateEditor,
      validate: {
        min: GANTT_COLUMNS_NAMES.actualStart,
      },
      hide: true,
      onrender: markWbsDatesDisabled,
    },
    {
      align: 'left',
      label: labels[GANTT_COLUMNS_NAMES.comments],
      name: GANTT_COLUMNS_NAMES.comments,
      resize: true,
      template: (task: GanttTask) => {
        return task.object_type === TaskObjectType.activity && task.comment_count > 0
          ? `
        <p>${task.comment_count}</p>
        ${getColumnIcon(IconTypeMap.CommentsIcon)}
        `
          : `<span></span>`;
      },
      width: 100,
    },
    generateColumn({
      align: 'left',
      editor: editors.zoneEditor,
      hide: false,
      label: getLabelWithInfoIcon(GANTT_COLUMNS_NAMES.zone, labels),
      name: GANTT_COLUMNS_NAMES.zone,
      template: (task: GanttTask) => {
        const zones =
          cacheHelper.queryClient.getQueryData<WakeCapZones[]>(QUERY_CACHE_KEYS.zones(task.projectId)) ?? [];
        if (task.object_type !== TaskObjectType.activity || task.wc_zone_id === undefined) {
          return '<span></span>';
        }
        const zone = zones?.find((zone) => zone.id === task.wc_zone_id);
        if (!zone?.zoneName) {
          return '<span></span>';
        }
        const zoneName = `${zone.zoneName}-${zone.spaceId}`;
        return `<p>${zoneName}</p>`;
      },
    }),
    generateColumn({
      align: 'left',
      editor: editors.crewEditor,
      hide: false,
      label: getLabelWithInfoIcon(GANTT_COLUMNS_NAMES.crew, labels),
      name: GANTT_COLUMNS_NAMES.crew,
      template: (task: GanttTask) => {
        const crews =
          cacheHelper.queryClient.getQueryData<WakeCapCrews[]>(QUERY_CACHE_KEYS.crews(task.projectId)) ?? [];

        if (task.object_type !== TaskObjectType.activity || !('wc_crew_id' in task) || !task.wc_crew_id) {
          return '<span></span>';
        }

        const crew = crews?.find((crew) => crew.crewId === task.wc_crew_id);

        if (!crew?.crewName) {
          return '<span></span>';
        }

        return crew.crewName;
      },
    }),
    generateColumn({
      align: 'left',
      hide: false,
      label: labels[GANTT_COLUMNS_NAMES.estLaborHours],
      name: GANTT_COLUMNS_NAMES.estLaborHours,
      attrs: {
        pattern: '^-?\\d*\\.?\\d*$', // allows negatives and floats
      },
      resize: true,
      template: (task: GanttTask) => {
        return task.object_type === TaskObjectType.activity
          ? `
          ${task.est_labor_hours ? `<p>${task.est_labor_hours}</p>` : '<span></span>'}
        `
          : null;
      },
    }),
    generateColumn({
      align: 'left',
      attrs: {
        pattern: '\\d+',
        maxlength: 100,
      },
      hide: false,
      label: getLabelWithInfoIcon(GANTT_COLUMNS_NAMES.estimatedLaborAbbr, labels),
      name: GANTT_COLUMNS_NAMES.estimatedLaborAbbr,
      resize: true,
      width: 150,
      template: (task) => {
        return task.object_type === TaskObjectType.activity
          ? `
            ${task.projected_labor ? `<p>${task.projected_labor}</p>` : '<span></span>'}
        `
          : null;
      },
    }),
    {
      hide: false,
      label: getLabelWithInfoIcon(GANTT_COLUMNS_NAMES.averageLaborAbbr, labels),
      name: GANTT_COLUMNS_NAMES.averageLaborAbbr,
      resize: true,
      width: 150,
      template: (task: GanttTask) => {
        return task.object_type === TaskObjectType.activity
          ? `
            ${task.feedback_rollup?.average_labor ? `<p>${task.feedback_rollup.average_labor}</p>` : '<span></span>'}
            ${getColumnIcon()}
        `
          : null;
      },
    },
    {
      hide: false,
      label: getLabelWithInfoIcon(GANTT_COLUMNS_NAMES.progress, labels),
      name: GANTT_COLUMNS_NAMES.progress,
      resize: true,
      width: 150,
      template: (task: GanttTask) => {
        const shouldShowProgress =
          !!task.feedback_rollup?.completion_amount && !!task.completion_target && !!task.completion_unit;
        const percentComplete =
          Math.floor(
            ((parseInt(task.feedback_rollup?.completion_amount) || 0) / parseInt(task.completion_target)) * 100,
          ) || 0;
        return task.object_type === TaskObjectType.activity
          ? `
          <div class="progress_cell">
            ${
              shouldShowProgress
                ? `
                <div class="progress_bar" style="width: ${percentComplete}%;"></div>
                <p class="progress_text">${percentComplete}%</p>`
                : '<span></span>'
            }
            ${getColumnIcon()}
          </div>
        `
          : null;
      },
    },
    {
      name: GANTT_COLUMNS_NAMES.inprogressDate,
      label: labels[GANTT_COLUMNS_NAMES.inprogressDate],
      align: 'left',
      width: 100,
      resize: true,
      hide: true,
    },
    {
      name: GANTT_COLUMNS_NAMES.doneDate,
      label: labels[GANTT_COLUMNS_NAMES.doneDate],
      align: 'left',
      width: 100,
      resize: true,
      hide: true,
    },
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.phaseCode,
        attrs: {
          maxlength: 12,
        },
        hide: true,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.costCode,
        attrs: {
          maxlength: 12,
        },
        hide: true,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.customCode,
        attrs: {
          maxlength: 12,
        },
        hide: true,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.csiCode,
        attrs: {
          maxlength: 12,
        },
        hide: true,
      },
      labels,
    ),
    {
      name: 'custom_add',
      hide: false,
      width: 45,
      template: () => `
          <button data-cy="gantt_more_info_btn" style="padding: 6px; background-color: transparent; border: none; margin-left: -11px;box-sizing: content-box" class="gantt__more-info" type="button">
            <span class="ctrl-btn-clear__text">Actions</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon--color-fill ctrl-btn-clear__icon more_info" width="24" height="24" fill="#A5A4A4">
                <path d="M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z"/>
                <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"/>
                <path d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z"/>
              </svg>
          </button>`,
    },
  ];
}

/* eslint-disable new-cap */
import {Data} from 'effect';
import {InterruptedException, UnknownException} from 'effect/Cause';

export class BadGatewayError extends Data.TaggedError('BadGatewayError')<{
  message: string;
  cause?: Error;
}> {}

export class HttpError extends Data.TaggedError('HttpError')<{
  statusCode: number;
  message: string;
  cause?: Error;
}> {}

export type EffectError = Error | InterruptedException | HttpError | BadGatewayError | UnknownException;

import axios, {CancelToken} from 'axios';
import {Effect} from 'effect';
import {InterruptedException} from 'effect/Cause';

import TasksApi from 'api/tasks';
import {LoadOptions} from 'modules/Tasks/components/Gantt/types';
import {SortOrder} from 'shared/constants/common';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {TaskProjection, TaskObjectType} from 'shared/models/task/const';
import {TaskListMinimalModel} from 'shared/models/task/task';

import {ApiResponse} from '../BatchLoader';
import {EffectError} from '../errors';

import {handleAxiosError} from './createIssueEffect';

export interface FetchActivityIdsBatchFnDeps {
  queryParams: LoadOptions['queryParams'];
  projectId: string;
  dataRange: LoadOptions['dataRange'];
  requestProjectId: string;
}

export interface FetchActivityIdsBatchParams {
  queryParams: LoadOptions['queryParams'];
  projectId: string;
  dataRange: LoadOptions['dataRange'];
}

export const createActivityIdsFetcherEffect = ({queryParams, projectId, dataRange}: FetchActivityIdsBatchParams) => {
  return (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ): Effect.Effect<ApiResponse<TaskListMinimalModel>, EffectError, never> =>
    Effect.tryPromise({
      try: () =>
        TasksApi.getProjectTasks({
          projection: TaskProjection.taskMinimal,
          offset,
          limit: take,
          sortField: 'outline_sort_key',
          sortOrder: SortOrder.ASC,
          includeSummaryTasks: true,
          params: addStatusToFilters(queryParams.state, {
            ...queryParams,
            objectTypeList: queryParams.milestonesOnly
              ? [TaskObjectType.milestone]
              : [TaskObjectType.task, TaskObjectType.summary, TaskObjectType.activity, TaskObjectType.milestone],
            projectId,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          }),
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }
        return handleAxiosError(error);
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data as TaskListMinimalModel[],
        headers: response.headers,
      })),
    );
};

import axios, {CancelToken} from 'axios';
import {Effect, Schedule, Duration} from 'effect';
import {InterruptedException, UnknownException} from 'effect/Cause';

import TasksApi from 'api/tasks';
import {SortOrder} from 'shared/constants/common';
import {SortField, TaskProjection} from 'shared/models/task/const';
import {TaskFilterQuery, TaskStatusQuery} from 'shared/models/task/filter';
import {TaskDetailsModelDTO, TaskModelRawDTO} from 'shared/models/task/task';

import {ApiResponse} from '../BatchLoader';
import {EffectError, BadGatewayError, HttpError} from '../errors';

export const createFetchBatch = (
  params: Partial<TaskFilterQuery & TaskStatusQuery>,
): ((
  offset: number,
  take: number,
  cancelToken: CancelToken,
) => Effect.Effect<ApiResponse<TaskDetailsModelDTO>, EffectError, never>) => {
  return (offset: number, take: number, cancelToken: CancelToken) =>
    Effect.tryPromise({
      try: () =>
        TasksApi.getProjectTasks({
          projection: TaskProjection.task,
          offset,
          limit: take,
          sortField: 'outline_sort_key',
          sortOrder: SortOrder.ASC,
          includeSummaryTasks: true,
          params,
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }

        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 502:
              return new BadGatewayError({
                message: error.message,
                cause: error,
              });
            default:
              return new HttpError({
                statusCode: error.response?.status || 500,
                message: error.message,
                cause: error,
              });
          }
        }
        return new UnknownException({
          message: error instanceof Error ? error.message : 'Unknown error',
          cause: error instanceof Error ? error : undefined,
        });
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data as unknown as TaskDetailsModelDTO[],
        headers: response.headers,
      })),
      Effect.retry(Schedule.exponential(Duration.seconds(2)).pipe(Schedule.compose(Schedule.recurs(3)))),
    );
};

export const createAllTasksFetchBatch = ({
  filterParams,
}: {
  filterParams: Partial<TaskFilterQuery & TaskStatusQuery>;
}) => {
  return (
    offset: number,
    take: number,
    cancelToken: CancelToken,
  ): Effect.Effect<ApiResponse<TaskModelRawDTO>, EffectError, never> =>
    Effect.tryPromise({
      try: () =>
        TasksApi.getProjectTasks({
          projection: TaskProjection.task,
          offset,
          limit: take,
          sortField: SortField.outlineSortKey,
          sortOrder: SortOrder.ASC,
          params: filterParams,
          cancelToken,
        }),
      catch: (error): EffectError => {
        if (axios.isCancel(error)) {
          return new InterruptedException('Request cancelled');
        }

        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 502:
              return new BadGatewayError({
                message: error.message,
                cause: error,
              });
            default:
              return new HttpError({
                statusCode: error.response?.status || 500,
                message: error.message,
                cause: error,
              });
          }
        }

        return new UnknownException({
          message: error instanceof Error ? error.message : 'Unknown error',
          cause: error instanceof Error ? error : undefined,
        });
      },
    }).pipe(
      Effect.map((response) => ({
        data: response.data as TaskModelRawDTO[],
        headers: response.headers,
      })),
    );
};
